import React, { useEffect, useState } from 'react'
import LabeledInput from '../components/LabeledInput'
import DatePicker from 'react-datepicker'
import { useParams } from 'react-router-dom'
import { getAlphaNumericWithSymbolsAndSpacesValidation, getNumberValidation, validate24HourTime } from '../../utils/validation'
import { AmplifyAPI } from '../../utils/api'
import CreateEventsList from '../components/CreateEventsList'
import ErrorMessage from '../components/ErrorMessage'
import { dateAndTimeStringsToDate } from '../../utils/date'
import { RiseLoader } from 'react-spinners'

const adminApi = new AmplifyAPI("AdminApi")

export default function EventsCreate() {
  const {season_id} = useParams()
  const [title, setTitle] = useState("")
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState("")
  const [count, setCount] = useState("1")
  const [teams, setTeams] = useState(null)
  const [teamId, setTeamId] = useState(null)
  const [locationId, setLocationId] = useState(null)
  const [locations, setLocations] = useState(null)
  const [events, setEvents] = useState([])
  const [length, setLength] = useState("")
  const [errorMsg, setErrorMsg] = useState("")
  const [fieldsValid, setFieldsValid] = useState({
    title: false,
    start: true,
    time: true,
    count: true,
    length: false,
    team: true,
    location: true
  })

  function getValidationWrapper(field, validationFunc) {
    return (str) => {
      const [valid, errMsg] = validationFunc(str)

      console.log(field, valid, errMsg)

      const newFieldsValid = {...fieldsValid}
      newFieldsValid[field] = valid

      for (const [key, val1] of Object.entries(newFieldsValid)) {
        const val2 = fieldsValid[key]
        if (val1 !== val2) {
          setFieldsValid(newFieldsValid)
          break
        }
      }

      return [valid, errMsg]
    }
  }

  function isValid() {
    console.log(fieldsValid)
    const validity = Object.values(fieldsValid)
    for (let i = 0; i < validity.length; i++) {
      if (!validity[i]) {
        return false
      }
    }

    return true
  }

  useEffect(() => {
    const path = "/seasons/teams"
    adminApi.get(path, {season_id: season_id}, results => {
      if (results == null) {
        results = []
      }

      setTeams(results)
    }, error => console.error(error))
  }, [season_id])

  useEffect(() => {
    const path = "/locations"
    adminApi.get(path, null, results => {
      if (results == null) {
        results = []
      }
      
      setLocations(results)
    }, error => console.error(error))
  }, [season_id])

  function generateEvents(submitEvent) {
    submitEvent.preventDefault()

    let newEvents = [...events]
    let currDate = Date.parse(date)
    for (let i = 0; i < parseInt(count); i++) {
      const newEvent = {
        id: crypto.randomUUID(),
        title: title,
        date: new Date(currDate),
        length: length,
        time: time,
        team_id: teamId,
        location_id: locationId,
      }

      console.log(newEvent)
      newEvents.push(newEvent)

      currDate += 1000 * 60 * 60 * 24 * 7 // add one week's worth of seconds
    }

    setEvents(newEvents)
  }

  function saveEvents(finallyCb) {
    console.log(events)

    const body = {events: []}
    for (let i = 0; i < events.length; i++) {
      const event = events[i]
      const date = dateAndTimeStringsToDate(event.date, event.time)

      const team_id = event.team_id === "" ? null : event.team_id
      const location_id = event.location_id === "" ? null : event.location_id
      body.events.push({
        title: event.title,
        start: date.toISOString(),
        length_mins: parseInt(event.length),
        season_id: season_id,
        team_id: team_id,
        location_id: location_id
      })
    }

    adminApi.post("/seasons/events", {}, body, results => {
      console.log(results)
      setEvents([])
    }, error => {
      console.error(error)
      setErrorMsg("Error saving events likely due to invalid fields. Please check fields and try again.")
    }, finallyCb)
  }

  if (teams == null || locations == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    console.log("render")
    return (
      <section className='admin-content-section'>
      <div className='admin-container'>
        <h3 className='div-center'>Events</h3>
        <div className='admin-row'>
          <LabeledInput label='* Title' 
            name='title' 
            delay={500} 
            validate={getValidationWrapper("title", getAlphaNumericWithSymbolsAndSpacesValidation(4, 128))} 
            handleChange={(valid,val) => setTitle(val)}/>
        </div>
        <div className='admin-row'>
          * Date
          <DatePicker id='start' className="inpt" selected={date} onChange={event => setDate(new Date(Date.parse(event)))}/>
        </div>
        <div className='admin-row'>
          <LabeledInput 
          name='start' 
          delay={500} 
          label='Time in PST 0:00 - 23:59 (Leave Blank if TBD)' 
          validate={getValidationWrapper("time", validate24HourTime)} 
          handleChange={(valid,val) => setTime(val)}/>
        </div>
        <div className='admin-row'>
          <LabeledInput 
          label='* Length Minutes' 
          name='length' 
          delay={500} 
          validate={getValidationWrapper("length", getNumberValidation(1,3))} 
          handleChange={(valid,val) => setLength(val)}/>
        </div>
        <div className='admin-row'>
          <LabeledInput 
          label='* Weekly for N weeks' 
          name='count' 
          delay={500} 
          initialValue={count} 
          validate={getValidationWrapper("count", getNumberValidation(1,2))}
          handleChange={(valid, val) => setCount(val)}/>
        </div>
        <div className='admin-row'>
          Team
          <select id='team_id' className='inpt' onChange={event => {
              console.log(event.target.value)
              setTeamId(event.target.value)
            }}>
            <option value="">All Players</option>
            {teams.map(team => {
              return (
                <option value={team.id}>{team.name}</option>
              )
            })}
          </select>
        </div>
        <div className='admin-row'>
          Location
          <select id='team_id' className='inpt' onChange={event => {
              console.log(event.target.value)
              setLocationId(event.target.value)
            }}>
            <option value="">TBD</option>
            {locations.map(loc => {
              return (
                <option value={loc.id}>{loc.name}</option>
              )
            })}
          </select>
        </div>
        <br/>
        <form onSubmit={submitEvent => generateEvents(submitEvent)} className="div-right">
          <button className='btn' disabled={!isValid()}>Generate</button>
        </form>
        <ErrorMessage text={errorMsg} />
      </div>
      <CreateEventsList teams={teams} locations={locations} events={events} setEvents={setEvents} submitEvents={saveEvents}/>
    </section>
    )
  }
}

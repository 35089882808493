import React from 'react'

export default function ErrorMessage({text}) {
  if (text !== "") {
    return <>
      <div className="error">{text}</div>
      <br/>
      </>
  }

  return null
}

export function GetLocalTZ() {
  const now = new Date().toLocaleDateString(undefined, { timeZoneName: 'short' })
  const lastSpace = now.lastIndexOf(" ")
  return now.substring(lastSpace + 1)
}

export function dateStrToDateAndTimeStrings(dateStr) {
  const date = new Date(Date.parse(dateStr))
  return dateToDateAndTimeStrings(date)
}

function twoDigit(n) {
  if (n < 10) {
    return "0" + n
  }

  return "" + n
}

export function dateToDateAndTimeStrings(date) {
  let time24 = twoDigit(date.getHours()) + ":" + twoDigit(date.getMinutes())
  if (time24 === "00:00") {
    time24 = ""
  }

  return [date.toDateString(), time24]
}

export function dateStrToDateAndTimeDisplayStrings(dateStr) {
  const date = new Date(Date.parse(dateStr))
  return dateToDateAndTimeDisplayStrings(date)
}

export function dateToDateAndTimeDisplayStrings(date) {
  let time = date.toLocaleTimeString()
  if (time === "12:00:00 AM") {
    time = "TBD"
  }

  return [date.toDateString(), time]
}

export function dateAndTimeStringsToDate(dateStr, timeStr) {
  const date = new Date(Date.parse(dateStr))

  if (timeStr && timeStr !== "") {
    const timeParts = timeStr.split(":")
    date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), 0, 0)
  } else {
    date.setHours(0, 0, 0, 0)
  }

  return date
}

export function dateStrToShortDate(dateStr) {
  const date = new Date(Date.parse(dateStr))
  return date.toLocaleDateString()
}
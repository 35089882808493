export function FormatPhoneNumber(str) {
  let formatted = ""
  for (let i = 0; i < str.length && i < 10; i++) {
      if (i === 3 || i === 6) {
          formatted += "."
      }

      formatted += str.charAt(i)
  }

  return formatted
}

export function TitleCase(str) {
  let formatted = ""
  let first = true
  for (let i = 0; i < str.length; i++) {
    let ch = str.charAt(i)
    if (first) {
      ch = ch.toUpperCase()
      first = false
    }

    if ((ch === ' ') || (ch === '\t') || (ch === '\n') || ch === '\r' || ch === '-' || ch === '_') {
      first = true
    }

    formatted += ch
  }

  return formatted
}

export function AgeForDOB(dob) {
  const now = Date.now()
  const diffSecs = (now - dob) / 1000
  const diffYears = Math.floor(diffSecs/(60*60*24*365.25))
  return diffYears
}
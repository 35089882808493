import React, {useState} from 'react'

export default function FileInput({onFileChanged}) {
  const [filename, setFilename] = useState("")
  return (
    <div className="fileinput-container">
      <div className="fileinput-div">
        <label className="btn fileinput-label">
          <input className="fileinput-input" type="file" onChange={event => {
            console.log(event.target.files)
            setFilename(event.target.files[0].name)
            onFileChanged(event)
          }}/>
          Choose File
        </label>
      </div>
      <div className="div-inline-block-full"><input className='inpt' disabled={true} value={filename} /></div>
    </div>
  )
}

import { Feed } from "./feed"
import { Roster } from "./roster"

export function ParseSeason(season) {
  return {
    ...season,
    start: new Date(Date.parse(season.start)),
    end: new Date(Date.parse(season.end))
  }
}

export function ParseEvent(event) {
  return {
    ...event,
    start: new Date(Date.parse(event.start))
  }
}

export function ParsePlayer(player) {
  let expStr = ""
  if (player.usalax_mem_expiration) {
    const exp = new Date(Date.parse(player.usalax_mem_expiration))
    expStr = "" + exp.getFullYear() + "-" + (exp.getMonth() + 1) + "-" + exp.getDate()
  }

  if (!player.middle) {
    player.middle = ""
  }

  return {
    ...player,
    dob: new Date(Date.parse(player.dob)),
    usalax_mem_expiration: expStr
  }
}

export function ParseEventDetails(event) {
  let location = null
  if (event.location != null) {
    location = {
      name: event.location.name,
      city: event.location.city,
      state: event.location.state,
      zip: event.location.zip,
      address: event.location.address,
      details: event.location.details,
      url: event.location.url
    }
  }

  return {
    title: event.event.title,
    start: new Date(Date.parse(event.event.start)),
    length: event.event.length_mins,
    is_rostered: event.event.team_id != null,
    location: location,
    season_id: event.event.season_id,
  }
}

export function ParseFeed(feedEvents, feedPlayers, locations) {
  return Feed.build(feedEvents, feedPlayers, locations)
}
import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AmplifyAPI } from '../../utils/api'
import { ParseEventDetails } from '../../utils/wire'
import { RiseLoader } from 'react-spinners'
import EventPlayers from '../components/EventPlayers'
import { dateToDateAndTimeDisplayStrings } from '../../utils/date'
import { Roster } from '../../utils/roster'


export function renderLocation(loc) {
  if (loc === null) {
    return (<div>TBD</div>)
  }

  let displayedLoc = (
    <>
      <div>{loc.name}</div>
      <div>{loc.address}</div>
      <div>{loc.city}, {loc.zip}</div>
    </>
  )

  if (loc.url && loc.url.length > 0) {
    return (
      <a href={loc.url} target="_blank" rel="noreferrer">
        {displayedLoc}
      </a>
    )
  } 

  return displayedLoc
}

function parseEventRosterData(data, event_id, players) {
  const eventAvailability = new Map()
    
  for (const cal_entry of data.event_to_cal_entries[event_id]) {
    eventAvailability.set(cal_entry.person_id, cal_entry.rsvp)
  }

  let parsedRoster = Roster.buildRoster(players, data.event_to_rostered_player_id[event_id])
  return {roster: parsedRoster, availability: eventAvailability}
}

const adminApi = new AmplifyAPI("AdminApi")

export default function Event() {
  const {season_id, event_id} = useParams()
  const [eventData, setEventData] = useState(null)
  const [players, setPlayers] = useState(null)

  useEffect(() => {
      adminApi.get("/events", {"event_id":event_id}, results => {
        console.log("Event results", results)
        setEventData(ParseEventDetails(results))
      }, error => console.error(error))

      adminApi.get("/seasons/players", {"season_id":season_id}, results => {
          console.log("get /seasons/players results", results)
          setPlayers(results)
      }, error => {
        console.error(error)
      })
  },[season_id, event_id])

  const [roster, setRoster] = useState(null)
  useEffect(() => {
    if (players == null) {
      return 
    }

    adminApi.get("/events/roster", {"event_id":event_id}, results => {
      console.log("get /events/roster results", results)
      setRoster(parseEventRosterData(results, event_id, players))
    }, error => console.error(error))
  }, [players, event_id])

  function handleRosterSave(event, newRoster) {
    let idToPlayerInfo = {}
    newRoster.forEachRostered(player => {
      idToPlayerInfo[player.id] = {
        position: player.position
      }
    })
    
    const body = {
      event_rosters: [{
        event_id: event_id,
        id_to_player_info: idToPlayerInfo
      }]
    }
    adminApi.post("/events/roster", {}, body, results => {
      console.log("post /events/roster", results)
      setRoster(parseEventRosterData(results, event_id, players))
    }, error => console.error(error))
  }

  if (eventData == null || roster == null || players == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    const [dateStr, timeStr] = dateToDateAndTimeDisplayStrings(eventData.start)
    return (
      <section className='admin-content-section'>
      <div className='admin-container-narrow'> 
        <div className='div-center'>
          <h2>Event Info
            <Link to={"edit"}><img src='/edit_icon.png' width='24px' height='24px' alt="edit"/></Link>
            <Link to={"delete"}><img src='/trash_icon.png' width='24px' height='24px' alt="edit"/></Link>
          </h2>
        </div>
        <div>
          <span className='event-info-label-span'>Event:</span>
          <span className='event-info-value-span'>{eventData.title}</span>
        </div>
        <div>
          <span className='event-info-label-span'>Date:</span>
          <span className='event-info-value-span'>{dateStr}</span>
        </div>
        <div>
          <span className='event-info-label-span'>Time:</span>
          <span className='event-info-value-span'>{timeStr}</span>
        </div>
        <div>
          <span className='event-info-label-span'>Length:</span>
          <span className='event-info-value-span'>{eventData.length} minutes</span>
        </div>
        <div>
          <span className='event-info-label-span'>Location:</span>
          <span className='div-right'>
            {renderLocation(eventData.location)}
          </span>
        </div>
      </div>
      <div className='admin-container'>
        <EventPlayers isRostered={eventData.is_rostered} initialRoster={roster.roster} availability={roster.availability} onRosterSave={handleRosterSave} />
      </div>
    </section>
    )
  }
}

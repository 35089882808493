import React from 'react'
import DateCardEventItem from './DateCardEventItem'

const monthNames = ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec']

export default function DateCard({parentKey, date, pidToEvents, feed, onRSVPChanged}) {
  const players = []
  const playerEvents = []
  pidToEvents.forEach((calEvents, pid) => {
    const player = feed.getPlayer(pid)
    players.push(player)
    playerEvents.push(calEvents)
  })

  const day = date.getDate()
  const month = monthNames[date.getMonth()]
  const year = date.getFullYear()
  return (
    <li className='datecard-li'>
      <div className='datecard-time-div'><h2 className='datecard-time-h2'>{day}<br/><span className='datecard-time-span'>{month},&nbsp;{year}</span></h2></div>
      <div className='datecardeventitem-container-div'>
      {players.map((player, i) => {
        const calEvents = playerEvents[i]
        const myKey = parentKey + "_" + i
        return (
          <DateCardEventItem key={myKey} parentKey={myKey} player={player} calEvents={calEvents} onRSVPChanged={onRSVPChanged} />)
      })}
      </div>
    </li>
  )
}

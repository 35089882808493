function CompareSeasonsByName(a,b) {
  for (let i = 0; i < a.name.length && i < b.name.length; i++) {
    // hack special case to sort k before numbers
    let aCh = a.name[i].toLowerCase()
    if (aCh === 'k') {
      aCh = '0'
    }

    let bCh = b.name[i].toLowerCase()
    if (bCh === 'k') {
      bCh = '0'
    }

    if (aCh < bCh) {
      return -1
    } else if (aCh > bCh) {
      return 1
    }
  }

  return a.name.length - b.name.length
}

function CompareSeasons(s1, s2) {
  if (s1.start < s2.start) {
    return -1
  } else if (s1.start > s2.start) {
    return 1
  } else {
    return CompareSeasonsByName(s1, s2)
  }
}

export function SortSeasons(seasons) {
  seasons.sort(CompareSeasons)
}

export function SortSeasonsByName(seasons) {
  seasons.sort(CompareSeasonsByName)
}

function CompareEvents(e1, e2) {
  if (e1.start < e2.start) {
    return -1
  } else if (e1.start > e2.start) {
    return 1
  }

  return 0
}

export function SortEvents(events) {
  events.sort(CompareEvents)
}
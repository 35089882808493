import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI } from '../../utils/api'
import { TitleCase } from '../../utils/formatting'
import ButtonOrBusy from '../components/ButtonOrBusy'

const adminApi = new AmplifyAPI("AdminApi")

export default function SeasonAssignPlayersToTeams() {
  const {season_id} = useParams()
  const [players, setPlayers] = useState(null)
  const [teams, setTeams] = useState(null)
  const [saveCount, setSaveCount] = useState(0)
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    adminApi.get("/seasons/teams/players", {season_id:season_id}, results => {  
      const listOfPlayers = []
      const teams = []
      if (results.teams != null) {
        for (let i = 0; i < results.teams.length; i++) {
          teams.push(results.teams[i])
          if (results.teams[i].players != null) {
            for (let j = 0; j < results.teams[i].players.length; j++) {
              const player = results.teams[i].players[j]
              player.team_id = results.teams[i].id
              player.team_name = results.teams[i].name

              listOfPlayers.push(player)
            }
          }
        }
      }

      teams.push({id:null, name:"No Team"})
      if (results.additional_players != null) {
        for (let i = 0; i < results.additional_players.length; i++) {
          const player = results.additional_players[i]
          player.team_id = null
          player.team_name = "No Team"
          listOfPlayers.push(player)
        }
      }

      listOfPlayers.sort((a,b) => {
        if (a.team_id === b.team_id) {
          if (a.first < b.first) {
            return -1
          } else if (a.first > b.first) {
            return 1
          } else {
            if (a.last < b.last) {
              return -1
            } else if (a.last > b.last) {
              return 1
            } else {
              return 0
            }
          }
        } else if (a.team_id === null || a.team_id < b.team_id) {
          return -1
        } else {
          return 1
        }
      })

      setPlayers(listOfPlayers)
      setTeams(teams)
    }, error => console.error(error))
  },[saveCount, season_id])

  if (players == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>      
    )
  } else {
    return (
      <section className='admin-content-section'>
        <div className='admin-container-narrow'>
          <table className='full-width'>
            {players.map((player, index) => {
              return (
                  <tr>
                    <td>{TitleCase([player.first,player.last].join(" "))}</td>
                    <td className='div-right'>
                      <select className='slct' onChange={event => {
                        const team_id = event.target.value !== "" ? event.target.value : null
                        const team_name = event.target.options[event.target.selectedIndex].text
                        const newPlayers = [...players]
                        newPlayers[index].team_id = team_id
                        newPlayers[index].team_name = team_name
                        setPlayers(newPlayers)
                      }}>
                        {teams.map((team, index) => {
                          const selected=player.team_id === team.id
                          const id = team.id != null ? team.id : ""
                          return (
                            <option value={id} selected={selected}>{team.name}</option>
                          )
                        })}
                      </select>
                    </td>
                  </tr>
              )
            })}
          </table>
          <br/>
          <form className='div-right' onSubmit={event => {
            event.preventDefault()

            const assignments = []
            for (let i = 0; i < players.length; i++) {
              const player = players[i]
              assignments.push({player_id:player.id, team_id:player.team_id})
            }
            
            setBusy(true)
            adminApi.post("/seasons/teams/players", {}, {season_id:season_id, assignments:assignments}, results => {
                console.log(results)
                setPlayers(null)
                setSaveCount(saveCount + 1)
              }, error => {
                console.error(error)
              }, () => {
                setBusy(false)
              }
            )
          }}>  
            <ButtonOrBusy className='btn btn-primary' type='submit' text='Save' busy={busy}/>
          </form>
        </div>
      </section>
    )
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import { AgeForDOB, TitleCase } from '../../utils/formatting'
import { ParsePlayer } from '../../utils/wire'
import DynamicTable from './DynamicTable'

function infoButton(url) {
  return (
    <Link to={url}>
      <img src="/info_icon.png" alt="info" width="24px" height="24px" />
    </Link>
  )
}

function editButton(url) {
  return (
    <Link to={url}>
      <img src="/edit_icon.png" alt="info" width="24px" height="24px" />
    </Link>
  )
}

function playerDataToTableData(playerData) {
  const tableData = []
  if (playerData) {
    for (let i = 0; i < playerData.length; i++) {
      const player = ParsePlayer(playerData[i])
      const row = {
        cols: [
          TitleCase([playerData[i].first, playerData[i].last].join(' ')),
          TitleCase(player.position),
          AgeForDOB(player.dob), 
          player.grad_year,
          infoButton("/admin/player/" + playerData[i].id),
          editButton("/admin/player/" + playerData[i].id + "/edit")
        ]
      }
        
      tableData.push(row)
    }
  }

  return tableData
}

export default function PlayerList(props) {
  const {players, teamEditLink, teamName, showIfEmpty} = props

  const playerHeaders = ["Name", "Position", "Age", "Grad Year","",""]
  const rows = playerDataToTableData(players)

  function getTitle() {
    if (teamEditLink) {
      return (
        <h3>{teamName}<Link to={teamEditLink}><img src="/edit_icon.png" height="24px" width="24px" alt="edit"/></Link></h3>
      )
    } else if (teamName && teamName.length > 0) {
      return (
        <h3>{teamName}</h3>
      )
    }
  }

  if (rows.length === 0 && !showIfEmpty) {
    return null
  }

  return (
    <>
      {getTitle()}
      <div>
        <DynamicTable headers={playerHeaders} rows={rows} />
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { AmplifyAPI } from '../../utils/api'
import { ParseSeason } from '../../utils/wire'
import { SortSeasons } from '../../utils/domain'
import SeasonsList from '../components/SeasonsList'
import { RiseLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

function splitSeasons(seasons, now) {
  console.log("splitting seasons", seasons, now)

  let active = []
  let upcoming = []

  for (let i = 0; i < seasons.length; i++) {
    const curr = ParseSeason(seasons[i])

    if (curr.start < now && curr.end > now) {
      active.push(curr)
    } else if (curr.start > now) {
      upcoming.push(curr)
    } else {
      console.log("dropping", curr.start, now)
    }
  }

  return [active, upcoming]
}

const adminApi = new AmplifyAPI("AdminApi")

export default function Seasons() {
  const [seasons, setSeasons] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    adminApi.get("/seasons", null, results => {
      console.log("/seasons response", results)

      if (results == null) {
        results = []
      }

      setSeasons(results)
    }, error => console.error(error))
  },[])

  if (seasons == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    const [activeSeasons, upcomingSeasons] = splitSeasons(seasons, Date.now())
    return (
      <section className='admin-content-section'>
        <SeasonsList seasons={activeSeasons} header='Active Seasons' />
        <SeasonsList seasons={upcomingSeasons} header='Upcoming Seasons' />
        <div className='admin-container-narrow-no-shadow div-right'>
          <button className='btn' onClick={() => navigate('/admin/season/new')}>Create</button>
        </div>
      </section>
    )
  }
}

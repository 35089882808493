import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

/*function getHeader(s) {
  let tokens = s.split("_br_")

  if (tokens.length === 1) {
    return (<>{s}</>)
  } else if (tokens.length === 2) {
    return (<>{tokens[0]}<br/>{tokens[1]}</>)
  } else if (tokens.length === 3) {
    return (<>{tokens[0]}<br/>{tokens[1]}<br/>{tokens[2]}</>)
  } else {
    throw new Error("Invalid header string has too many line breaks: " + s)
  }
}*/

export default function DynamicTable({headers, rows, maxRows}) {
  const navigate = useNavigate()
  const [keyPrefix,] = useState(crypto.randomUUID())

  if (!maxRows) {
    maxRows = -1
  }

  let displayedRows = 0
  let allRows = 0
  if (rows) {
    allRows = rows.length
    displayedRows = allRows
  }

  if (headers) {
    if (!rows) {
      rows = []
    }
  } else if (rows) {
    headers = []
  } else {
    return <></>
  }

  if (displayedRows > maxRows && maxRows > 0) {
    rows=rows.slice(0, maxRows)
    displayedRows = maxRows
  }

  const renderDisplayedOutOf = () => { 
    if (displayedRows < allRows) {
      console.log("displayedRows < allRows")
      return (
      <div className="dynamictable-info-div">
        <p className="dynamictable-info-txt">Displaying {displayedRows} of {allRows} rows</p>
      </div>
      )
    }
    return null
  }

  return (
    <div className="dynamictable-container-div" key={keyPrefix + "_dynamic_table_div"}>
      <div className="dynamictable-table-container-div">
        <table className="dynamictable-table">
          <thead>
            <tr className="dynamictable-header-row">
              {headers.map((header,idx) => {
                return (<th key={keyPrefix + "_" + idx} className="dynamictable-header-val">{header}</th>)
              })}
            </tr>
          </thead>
          <tbody>
          {rows.map((row,rowIdx) => {
            let rowStyle = "dynamictable-row"
            let onClickFunc = null
            if (row.url && row.url.length > 0) {
              rowStyle += " dynamictable-clickable-row"
              onClickFunc = () => {navigate(row.url)}
            }

            return (
              <tr key={keyPrefix + "_row_" + rowIdx} className={rowStyle} onClick={onClickFunc}>
                {row.cols.map((col,colIdx) =>{
                  return (<td key={keyPrefix + "_row_" + rowIdx + "_col_" + colIdx} className="dynamictable-row-val">{col}</td>)
                })}
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
      {renderDisplayedOutOf()}
    </div>
  )
}

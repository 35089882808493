import React, { useEffect, useState } from 'react'
import '../css/App.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import DynamicTable from '../components/DynamicTable'
import { AmplifyAPI } from '../../utils/api'
import { ParseEvent, ParseSeason } from '../../utils/wire'
import { SortEvents } from '../../utils/domain'
import { RiseLoader } from 'react-spinners'
import PlayerList from '../components/PlayerList'
import { dateToDateAndTimeDisplayStrings } from '../../utils/date'

function splitEvents(eventData) {
  const now = Date.now()

  let upcomingEvents = []
  let pastEvents = []
  for (let i = 0; i < eventData.length; i++) {
    const event = ParseEvent(eventData[i])
    if (event.start < now) {
      pastEvents.push(event)
    } else {
      upcomingEvents.push(event)
    }
  }

  SortEvents(upcomingEvents)
  SortEvents(pastEvents)

  return [
    {
      groupTitle: "Upcoming Events",
      events: upcomingEvents
    },{
      groupTitle: "Past Events",
      events: pastEvents
    }
  ]
}

function eventDataToTableData(eventData, teams, locations) {
  const tableData = []
  for (let i = 0; i < eventData.length; i++) {
    const event = eventData[i]
    const team = event.team_id == null || event.team_id.length === 0 ? "All Players" : teams.find(t => t.id === event.team_id).name
    const loc = event.location_id == null || event.location_id.length === 0 ? "TBD" : locations.find(l => l.id === event.location_id).name
    const [date, time] = dateToDateAndTimeDisplayStrings(event.start)

    const row = {
      url: "event/" + event.id,
      cols: [
        event.title, 
        date,
        time,
        team,
        loc
      ]
    }
    
    tableData.push(row)
  }

  return tableData
}

const adminApi = new AmplifyAPI("AdminApi")

export default function Season() {
  const navigate = useNavigate()

  const {id} = useParams()
  const [season, setSeason] = useState(null)
  useEffect(() => {
    const path = "/seasons"
    const params = {"season_id":id}

    adminApi.get(path, params, results => {
      console.log("Season results", results)
      setSeason(ParseSeason(results))
    }, error => console.error(error))
  },[id])

  const [playerData, setPlayerData] = useState(null)
  useEffect(() => {
    const path = "/seasons/teams/players"
    const params = {"season_id":id}
    adminApi.get(path, params, results => {
      if (!results) {
        results = []
      }
      
      console.log("Team results:", results)
      setPlayerData(results)
    }, error => console.error(error))
  },[id])

  const [eventData, setEventData] = useState(null)
  useEffect(() => {
    const path = "/seasons/events"
    const params = {"season_id":id}
    adminApi.get(path, params, results => {
      console.log("Event results:", results)
      if (!results) {
        results = []
      }
      
      setEventData(results)
    }, error => console.error(error))
  },[id])


  const [locations, setLocations] = useState(null)
  useEffect(() => {
    const path = "/locations"
    adminApi.get(path, null, results => {
      if (!results) {
        results = []
      }

      setLocations(results)
    }, error => console.error(error))
  }, [id])

  if (eventData == null || playerData == null || season == null || locations == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    const eventHeaders = ["Event Name", "Date", "Time", "Team", "Location"]
    const splitEventData = splitEvents(eventData)

    return (
      <section className='admin-content-section'>
        <h2>{season.name}<Link to={"/admin/season/" + id + "/edit"}><img src="/edit_icon.png" height="24px" width="24px" alt="edit"/></Link></h2>
        <div className='admin-container'>
          <div className='div-center'><h3>Players</h3></div>
          {playerData.teams.map(team => {
            const editLink = "/admin/season/" + id + "/team/" + team.id + "/edit"
            return (<PlayerList players={team.players} teamEditLink={editLink} teamName={team.name} showIfEmpty={true} key={"team_" + team.id + "_players"}/>) 
          })}
          <PlayerList players={playerData.additional_players} teamName="Unassigned Players" showIfEmpty={false}/>
        <br/>
        <div className='div-right'>
          <button className='btn btn-primary' onClick={() => navigate('/admin/season/'+id+'/players')}>Edit Players</button>&nbsp;
          <button className='btn btn-primary' onClick={() => navigate('/admin/season/'+id+'/team/new')}>Create Team</button>&nbsp;
          <button className='btn btn-primary' onClick={() => navigate('/admin/season/'+id+'/players/assign')}>Assign Players To Teams</button>
        </div>
        </div>
        <div className='admin-container'>
          <div className='div-center'><h3>Events</h3></div>
          {splitEventData.map(eventGrouping => {
            if (eventGrouping.events.length === 0) {
              return null
            }
            let events = eventDataToTableData(eventGrouping.events, playerData.teams, locations)
            return (
              <>
                <h3>{eventGrouping.groupTitle}</h3>
                <div>
                  <DynamicTable headers={eventHeaders} rows={events} />
                </div>
              </>
            )
          })}
          <br/>
          <div className='div-right'>
            <button className='btn btn-primary' onClick={() => navigate('/admin/season/'+id+'/events/new')}>Create Events</button>&nbsp;
          </div>
        </div>
      </section>
    )
  }
}

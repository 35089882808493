import React, { useState } from 'react'
import ErrorMessage from './ErrorMessage';
import { FormatPhoneNumber } from '../../utils/formatting';

export default function PhoneNumberInput(props) {
    const [value, setValue] = useState("")
    const [errMsg, setErrMsg] = useState("")

    const {label, name, setPhoneNumber } = props
    const inputProps = { name: name, id: name, disabled: props.disabled}

    function phoneStripFormatting(str) {
        let unformatted = ""
        for (let i = 0; i < str.length && unformatted.length < 10; i++) {
            const ch = str.charAt(i)
            if (ch >= '0' && ch <= '9') {
                unformatted += ch
            }
        }

        return unformatted
    }

    function handleValueChanged(str) {
        const stripped = phoneStripFormatting(str)
        const formatted = FormatPhoneNumber(stripped)
        setValue(formatted)

        if (stripped.length === 10) {
            setPhoneNumber(stripped)
        } else {
            setPhoneNumber("")
        }
    }

    function validate(str) {
        if (str.length === 0 || str.length === 12) {
            setErrMsg("")
        } else {
            setErrMsg("A valid US phone number should have 10 digits")
        }
    }

    return (
        <div>
            <label htmlFor={name}>{label}</label>
            {(() => {
                return (<input
                    {...inputProps}
                    className="inpt"
                    onChange={event => handleValueChanged(event.target.value)}
                    onBlur={event => validate(event.target.value)}
                    onFocus={() => setErrMsg("")}
                    value={value}/>)
            })()}
            <ErrorMessage text={errMsg} />
        </div>
      )
}

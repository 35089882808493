export function objectsEqual(a, b) {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)

  if (!arraysEqual(aKeys, bKeys)) {
    return false
  }

  for (const [key, val1] of Object.entries(a)) {
    const val2 = b[key]
    if (val1 !== val2) {
      return false
    }
  }

  return true
}

export function arraysEqual(a, b) {
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; i++) {
    const val1 = a[i]
    const val2 = b[i]
    if (val1 !== val2) {
      return false
    }
  }

  return true
}
import React, { useState, useEffect } from 'react'
import { ParsePlayer } from '../../utils/wire';
import { TitleCase, AgeForDOB } from '../../utils/formatting'
import DynamicTable from '../components/DynamicTable';
import LabeledInput from '../components/LabeledInput';
import { RiseLoader } from 'react-spinners';
import { AmplifyAPI } from '../../utils/api';

const adminApi = new AmplifyAPI("AdminApi")

export default function Players() {

  const [players, setPlayers] = useState(null)
  const [firstFilter, setFirstFilter] = useState("")
  const [lastFilter, setLastFilter] = useState("")

  function filterPlayers(players) {
    let filters = null
    try {
      filters = [
        {field:"first", filterVal:firstFilter, filterRegex: new RegExp(firstFilter)}, 
        {field:"last", filterVal:lastFilter, filterRegex: new RegExp(lastFilter)}
      ]
    } catch(error) {
      console.error(error)
      return players
    }


    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]
      if (filter.filterVal === "") {
        
      } else {
        const filteredPlayers = []
        for (let j = 0; j < players.length; j++) {
          const player = players[j]
          if (filter.filterRegex.test(player[filter.field].toLowerCase())) {
            filteredPlayers.push(player)
          }
        }

        players = filteredPlayers
      }
    }

    return players
  }

  useEffect(() => {
      adminApi.get("/players", null, results => {
        let players = []
        if (results.players != null) {
          for (let i = 0; i < results.players.length; i++) {
            players.push(ParsePlayer(results.players[i])) 
          }
        }
        console.log("players", players)
        setPlayers(players)
      }, error => console.error(error))
  },[])

  if (players == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  }

  const filtered = filterPlayers(players)
  const headers = ["Name", "Age", "Grad Year", "Position", "USA LAX #", "USA LAX Expiration"]
  const rows = []
  for (let i = 0; i < filtered.length; i++) {
    const player = filtered[i]
    rows.push({
      url: "../player/" + player.id,
      cols: [
        TitleCase([player.first,player.last].join(' ')), 
        AgeForDOB(player.dob), 
        player.grad_year,
        TitleCase(player.position),
        player.usalax_mem_number,
        player.usalax_mem_expiration,
      ],
    })
  }
  
  return (
    <section className='admin-content-section'>
      <div className='admin-container'>
        <div className="admin-row">
          <LabeledInput name="first" label="First:" type="text" maxLength={128} delay={500} handleChange={(valid, value) => setFirstFilter(value.toLowerCase())}/>
        </div>
        <div className="admin-row">
          <LabeledInput name="last" label="Last:" type="text" maxLength={128} delay={500} handleChange={(valid, value) => setLastFilter(value.toLowerCase())}/>
        </div>
        <div className="admin-row">
          <DynamicTable headers={headers} rows={rows}/>
        </div>
      </div>
    </section>
  )
}

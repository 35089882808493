import React, { useState } from 'react'

export default function DeleteEntity({api, path, args, onDelete}) {
  const [text, setText] = useState('')

  function inputChanged(text) {
    setText(text)
  }

  function deleteEntity() {
    api.delete(path, args, results => {
      console.log("DELETE results", results)
      onDelete(true, "")
    }, error => {
      console.error(error)
      onDelete(false, error.message)
    })
  }

  return (
    <>
      <div>
        <input className="inpt" type='text' placeholder='Type "delete" here' onChange={event => inputChanged(event.target.value)} value={text} />
      </div>
      <br/>
      <div className='div-right'>
        <button className="btn" onClick={deleteEntity} disabled={text !== "delete"} >Delete</button>
      </div>
    </>
  )
}

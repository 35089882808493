import React, { useEffect, useState } from 'react'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI } from '../../utils/api'
import { dateStrToDateAndTimeDisplayStrings } from '../../utils/date'
import { ParseFeed } from '../../utils/wire'
import DateCard from '../components/DateCard'
import { Auth } from 'aws-amplify'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppVersion } from '../../AppVersion'

const userAPI = new AmplifyAPI("UserApi")

  const apiArgs = {}
export default function UserHome() {
  // read query string params
  const [searchParams] = useSearchParams()
  if (searchParams.has('email')) {
    apiArgs['email'] = searchParams.get('email')
  }

  const navigate = useNavigate();
  const [locations, setLocations] = useState(null)
  const [loadedAt] = useState(Date.now())

  // install a handler that will refresh the page on focus, if it was loaded over an hour ago
  useEffect(() => {
    if (loadedAt == null) {
      return
    }

    window.addEventListener("focus", () => {
      console.log("window focused")
    
      const elapsed = Date.now() - loadedAt
      if (elapsed > 60*60*1000) {
        window.location.reload(true)
      }
    })
   }, [loadedAt])

  //Every 5 minutes refresh the feed and direct the user to /signin if they have been logged out
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("refreshing feed")

      Auth.currentUserPoolUser().catch(error => {
        console.log("not logged in. redirecting to signin")
        navigate("/signin")
      }).then(() => {
        if (locations == null) {
          return
        }
      
        userAPI.get("/user/feed", apiArgs, results => {
          //console.log("GET /user/feed", results)
          const feedData = ParseFeed(results['feed'], results['players'], locations)
          setFeed(feedData)
        }, error => console.error(error))
      })
    }, 300000)
    return () => clearInterval(interval)
  }, [locations, apiArgs])

  // get the locations
  useEffect(() => {
    userAPI.get("/locations", apiArgs, results => {
      console.log("GET /locations", results)
      if (results == null) {
        results = []
      }
      
      const locations = new Map()
      for (const location of results) {
        locations.set(location.id, location)
      }
      setLocations(locations)
    }, error => console.error(error))
  }, [apiArgs])

  // get the feed of events
  const [feed, setFeed] = useState(null)
  useEffect(() => {
    if (locations == null) {
      return
    }

    userAPI.get("/user/feed", apiArgs, results => {
      console.log("GET /user/feed", results)
      const feedData = ParseFeed(results['feed'], results['players'], locations)
      setFeed(feedData)
    }, error => console.error(error))
  }, [locations, apiArgs])

  function handleRSVPChanged(calEvent, rsvp, successCb, errCb) {
    // make a copy of api args
    const putArgs = {"personal_event_id":calEvent["person_cal_entry_id"]}
    for (const key in apiArgs) {
      putArgs[key] = apiArgs[key]
    }
    
    userAPI.put("/user/event", putArgs, {"rsvp": rsvp}, result => {
      console.log("PUT /user/event succeeded", result)
      successCb(rsvp)
    }, error => {
      console.error("PUT /user/event failed", error)
      errCb(error)
    })
  }

  if (feed == null || locations == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    //const feedStart = new Date("2023-01-01")
    const feedStart = new Date(Date.now())
    const eventsByDate = feed.getEventsAfter(feedStart)
    if (eventsByDate.length === 0) {
      return (
        <section className='userhome-section'>
          <div className='userhome-events-div'>
            <h3 className='div-center'>No events found</h3>
          </div>
        </section>
      )
    } else {
      return (
        <>
          <section className='userhome-section'>
            <div className='userhome-events-div'>
              <ul className='userhome-events-ul'>
                {eventsByDate.map((calEvents, i) => {
                  const pidToEvents = feed.groupEventsByPlayer(calEvents)
                  const [date] = dateStrToDateAndTimeDisplayStrings(calEvents[0].start)
                  const myKey = "datecard_" + i
                  return (<DateCard key={myKey} parentKey={myKey} date={new Date(Date.parse(date))} pidToEvents={pidToEvents} feed={feed} onRSVPChanged={handleRSVPChanged} />)
                })}
              </ul>
            </div>
          </section>
          <div className='div-center'>Player Portal Version: {AppVersion}<br/>Loaded At: {new Date(loadedAt).toLocaleString()}</div>
        </>
      )
    }
  }
}

import React from 'react'
import { TitleCase } from '../../utils/formatting'
import DateCardEventItemDetails from './DateCardEventItemDetails'

export default function DateCardEventItem({parentKey, player, calEvents, onRSVPChanged}) {
  function splitEvents(i, len) {
    if (i < len -1) {
      return (<><hr/></>)
    }
  }

  const teams = player["team"]
  const team_ids = new Set()
  if (teams != null) {
    for (let i = 0; i < teams.length; i++) {
      team_ids.add(teams[i]["teamId"])
    }
  }

  calEvents.sort((a,b) => {
    if (a["start"] < b["start"]) {
      return -1
    } else {
      return 1
    }
  })

  return (
    <div>
      <h1 className='datecardeventitem-h1'>{TitleCase([player.first, player.last].join(' '))}</h1>
      {calEvents.map((calEvent, i) => {
        return (
          <div key={parentKey + "_" + i}>
            <DateCardEventItemDetails player={player} initialCalEvent={calEvent} onRSVPChanged={onRSVPChanged}/>
            {splitEvents(i, calEvents.length)}
          </div>
        )
      })}
    </div>
  )
}

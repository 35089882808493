import { TitleCase } from "./formatting"

export class Field {
  constructor(name, maxLen, validator) {
    this.name = name
    this.display = TitleCase(name)
    this.validator = validator
    this.type = "input"
    this.maxLen = maxLen
    this.required = false
    this.options = null
    this.constVal = null
    this.emptyAsNull = false
  }

  withDisplayName(display) {
    this.display = display
    return this
  }

  withType(type) {
    this.type = type
    return this
  }

  withOptions(options) {
    this.options = options
    return this
  }

  withRequired() {
    this.required = true
    return this
  }

  withConstValue(constVal) {
    this.constVal = constVal
    return this
  }

  withEmptyAsNull() {
    this.emptyAsNull = true
    return this
  }
}

export class FormFields {
  constructor() {
    this.fields = []
  }

  add(field) {
    this.fields.push(field)
  } 

  get(name) {
    return this.fields.find(field => field.name === name)
  }
}
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI } from '../../utils/api'
import { TitleCase } from '../../utils/formatting'
import DynamicTable from '../components/DynamicTable'
import ErrorMessage from '../components/ErrorMessage'

const userApi = new AmplifyAPI("UserApi")
const bulletCharacter = "◎"

export default function UserRoster() {
  const {event_id} = useParams()
  const navigate = useNavigate()

  const [errMsg, setErrMsg] = useState("")
  const [userRoster, setUserRoster] = useState(null)
  useEffect(() => {
    userApi.get("/rosters", {event_id: event_id}, results => {
      setUserRoster(results)
    }, error => {
      console.error(error)
      setErrMsg(error.message)
    })
  }, [event_id])


  if (errMsg.length > 0) {
    return (
      <div className='div-center'>
        <div className='div-loader'>
          <ErrorMessage text={errMsg}/>
          <button className='btn' onClick={() => navigate(-1)}>Back</button>
        </div>
      </div>
    )
  } else if (userRoster == null) {
    return (
      <div className='div-center'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </div>
    )
  } else {
    const headers = ["Name", "Goalie"]
    const rows = []
    for (const player of userRoster) {
      rows.push({
        cols: [
          TitleCase([player.first_name, player.last_name].join(' ')), 
          player.position !== "nongoalie" ? bulletCharacter : ""]
      })
    }

    rows.sort((a, b) => {
      if (a.cols[1] !== b.cols[1]) {
        if (a.cols[1] === bulletCharacter) {
          return -1
        } else {
          return 1
        }
      } else {
        if (a.cols[0] < b.cols[0]) {
          return -1
        } else {
          return 1
        }
      }
    })
    return (
      <section className='user-roster-content-section'>
        <div className='user-roster-div'>
          <DynamicTable headers={headers} rows={rows} />
        </div>
        <button className='btn' onClick={() => navigate(-1)}>Back</button>
      </section>
    )
  }
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI, formFieldsToObject } from '../../utils/api'
import DynamicForm from '../components/DynamicForm'
import { teamFormFields } from './FormDefs'

export function getHandleTeamSubmit(season_id, adminApi, navigate) {
  return function handleTeamSubmit(event) {
    event.preventDefault()
    const data = formFieldsToObject(teamFormFields, event)
    adminApi.post("/seasons/teams", {}, data, results => {
      console.log("POST /seasons/teams results", results)
      navigate('/admin/season/' + season_id)
    }, error => console.error(error))
  }
}

const adminApi = new AmplifyAPI("AdminApi")

export default function TeamEdit() {
  const navigate = useNavigate()
  const {season_id, team_id} = useParams()
  const [team, setTeam] = useState(null)

  useEffect(() => {
    const path = "/seasons/teams"
    adminApi.get(path, {"team_id":team_id}, results => {
      console.log("GET /seasons/teams", results)
      setTeam(results)
    }, error => console.error(error))
  }, [team_id])

  if (team == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    return (
      <section className='admin-content-section'>
        <div className='admin-container-narrow'>
          <h3 className='div-center'>Location</h3>
          <DynamicForm fields={teamFormFields} initialValue={team} handleSubmit={getHandleTeamSubmit(season_id, adminApi, navigate)}/>
        </div>
      </section>
    ) 
  }
}
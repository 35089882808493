import React from 'react'
import DynamicTable from './DynamicTable'

export default function InviteList({invites}) {
  function linkForInvite(invite) {
    // args := fmt.Sprintf("email=%s&invite=%s", email, code)
	  // encoded := base64.StdEncoding.EncodeToString([]byte(args))
	  // return fmt.Sprintf("https://players.palilax.net/signup/%s", encoded)
    const args = "email=" + invite.email + "&invite=" + invite.code
    const encoded = btoa(args)
    return "https://players.palilax.net/signup/" + encoded
  }

  const headers = ["email", "expires_at", "sent_at", "link"]
  const rows = []
  
  if (invites !== null) {
    for (let i = 0; i < invites.length; i++) {
      let sentAtStr = "unsent"
      if (invites[i].sent_at != null) {
        sentAtStr = invites[i].sent_at
      }
      
      const invite = invites[i]
      const row = {cols:[invite.email, invite.expires_at, sentAtStr, linkForInvite(invite)]}
      rows.push(row)
    }
  }

  return (
    <DynamicTable  headers={headers} rows={rows}/>
  )
}

import React, { useState } from 'react'
import './css/App.css'
import LabeledInput from './components/LabeledInput';
import ErrorMessage from './components/ErrorMessage';
import { ValidateEmail, ValidateStrFunc } from '../utils/validation';
import { Auth, API } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';

export default function SignIn() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [canSubmit, setCanSubmit] = useState(false)
    const [signinFailMsg, setSigninFailMsg] = useState("")
    const [validEmail, setValidEmail] = useState(false)
    const [validPass, setValidPass] = useState(false)
    const navigate = useNavigate();

    function handleEmailChanged(valid, val) {
        setValidEmail(valid)
        setEmail(val)
        setCanSubmit(valid && validPass)
    }

    const validatePassword = ValidateStrFunc(null, 8, 32)
    function handlePasswordChanged(valid, val) {
        setValidPass(valid)
        setPassword(val)
        setCanSubmit(valid && validEmail)
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (canSubmit) {
            setCanSubmit(false)

            const req = {
                username: email,
                password: password
            }

            Auth.signIn(req).then(x => {
                setSigninFailMsg("")
                API.get("AdminApi", "/players").then(() => {
                  navigate("/admin")
                }).catch(error => {
                  navigate("/user")
                })
            }).catch(error => {
                console.log('error signing up:', error);
                setSigninFailMsg(error.message)
                setCanSubmit(true)
            })
        }
    }

    return (    
        <div className="signin-root-div">
            <div className="signin-container">
                <div className="signin-img-div">
                    <img className="signin-img-div" src="/breakers_logo.png" alt="Palisades Lacrosse" />
                </div>
                <form onSubmit={event => handleSubmit(event)}>
                    <div className="signin-row">
                        <LabeledInput name="email" label="Email:" type="email" maxLength={128} validate={ValidateEmail} handleChange={handleEmailChanged} delay={500}/>
                    </div>
                    <div className="signin-row">
                        <LabeledInput name="password" label="Password:" type="password" maxLength={32} validate={validatePassword} handleChange={handlePasswordChanged} delay={50}/>
                    </div>
                    <div className="signin-row div-right">
                        <button className="btn" disabled={!canSubmit}>Submit</button>
                    </div>
                    <ErrorMessage text={signinFailMsg} />
                </form>
                <div className="signin-row">
                    <Link to="/signup">Sign Up</Link> for an Account
                </div>
                <div className="signin-row">
                    <Link to="/reset">Reset</Link> Forgotten Password
                </div>
            </div>
        </div>
    )
}

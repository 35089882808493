import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import ButtonOrBusy from './ButtonOrBusy'

export default function CreateEventsList({teams, locations, events, setEvents, submitEvents}) {
  const [busy, setBusy] = useState(false)

  function changeEventDate(i, newDate) {
    const newEvents = [...events]
    newEvents[i].date = newDate
    setEvents(newEvents)
  }

  function changeTitle(i, newTitle) {
    const newEvents = [...events]
    newEvents[i].title = newTitle
    setEvents(newEvents)
  }

  function changeTeam(i, newTeamId) {
    const newEvents = [...events]
    newEvents[i].team_id = newTeamId
    setEvents(newEvents)
  }

  function changeLocation(i, newLocationId) {
    const newEvents = [...events]
    newEvents[i].location_id = newLocationId
    setEvents(newEvents)
  }

  function changeTime(i, newTime) {
    const newEvents = [...events]
    newEvents[i].time = newTime
    setEvents(newEvents)

  }

  if (events == null || events.length === 0) {
    return null
  }

  return (
    <div className='admin-container'>
      <table className='full-width'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Date</th>
            <th>Time</th>
            <th>Team</th>
            <th>Location</th>
          </tr>
        </thead>
        {events.map((event, i) => {
          return (
            <tbody>
              <tr>
                <td><input className="inpt" value={event.title} onChange={e => changeTitle(i, e.target.value)}/></td>
                <td><DatePicker id={"date-" + event.id} className="inpt" selected={event.date} onChange={newDate => changeEventDate(i, newDate)}/></td>
                <td><input className="inpt" value={event.time} onChange={e => changeTime(i, e.target.value)}/></td>
                <td>
                  <select className='inpt' defaultValue={event.team_id} onChange={value => changeTeam(i, value)}>
                    <option value="">All Players</option>
                    {teams.map(team => {
                      return (
                        <option value={team.id}>{team.name}</option>
                      )
                    })}
                  </select>
                </td>
                <td>
                  <select className="inpt" defaultValue={event.location_id} onChange={value => changeLocation(i, value)}>
                    <option value="">TBD</option>
                    {locations.map(loc => {
                      return (
                        <option value={loc.id}>{loc.name}</option>
                      )
                    })}
                  </select>
                </td>
              </tr>
            </tbody>
          )
        })}
      </table>
      <br/>
      <form className='div-right' onSubmit={e => {
        e.preventDefault()
        setBusy(true)
        submitEvents(() => setBusy(false))
      }}>
        <ButtonOrBusy className='btn btn-primary' type='submit' text='Save Events' busy={busy}/>
      </form>
    </div>
  )
}

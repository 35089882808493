import React from 'react'

export default function EventPlayersSave({numDiffs, roster, onRosterSave}) {
  const disabled = (numDiffs === 0) ? true : false
  return (
    <div className='div-right'>
      <button className='btn' onClick={event => onRosterSave(event, roster)} disabled={disabled}>save</button>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI, formFieldsToObject } from '../../utils/api'
import { ParseSeason } from '../../utils/wire'
import DynamicForm from '../components/DynamicForm'
import { seasonFormFields } from './FormDefs'

export function getHandleSeasonSubmit(adminApi, navigate) {
  return function handleSeasonSubmit(event) {
    event.preventDefault()
    const data = formFieldsToObject(seasonFormFields, event)
    console.log("POST /seasons data", data)
    adminApi.post("/seasons", {}, data, results => {
      console.log("POST /seasons results", results)
      navigate('/admin/seasons')
    }, error => console.error(error))
  }
}

const adminApi = new AmplifyAPI("AdminApi")
export default function SeasonEdit() {
  const navigate = useNavigate()
  const handleSeasonSubmit = getHandleSeasonSubmit(adminApi, navigate)

  const {id} = useParams()
  const [season, setSeason] = useState(null)
  useEffect(() => {
    const path = "/seasons"
    const params = {"season_id":id}

    adminApi.get(path, params, results => {
      setSeason(ParseSeason(results))
    }, error => console.error(error))
  },[id])

  if (season == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    return (
      <section className='admin-content-section'>
        <div className='admin-container-narrow'>
          <h3 className='div-center'>Season</h3>
          <DynamicForm fields={seasonFormFields} initialValue={season} handleSubmit={handleSeasonSubmit}/>
        </div>
      </section>
    )
  }
}

import React, { useEffect, useState } from 'react'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI } from '../../utils/api'
import { SortSeasonsByName } from '../../utils/domain'
import EventMultiSelect from '../components/EventMultiSelect'
import SeasonDropdown from '../components/SeasonDropdown'
import ButtonOrBusy from './ButtonOrBusy'
import ErrorMessage from './ErrorMessage'

const adminApi = new AmplifyAPI("AdminApi")

function getSelectedEvents(events, eventIds) {
  const selectedEvents = []
  for (const eventId of eventIds) {
    const event = events.find(event => event.id === eventId)
    if (event != null) {
      selectedEvents.push(event)
    }
  }

  return selectedEvents
}

export default function RosterEditOptions({loading, setRosterEditorArgs, setSummariesOnly}) {
  const [errMsg, setErrMsg] = useState("")
  const [seasons, setSeasons] = useState(null)
  const [seasonId, setSeasonId] = useState(null)
  const [seasonName, setSeasonName] = useState("")
  useEffect(() => {
    setErrMsg("")

    const path = "/seasons"
    adminApi.get(path, {}, results => {
      if (results == null) {
        results = []
      }

      SortSeasonsByName(results)
      setSeasons(results)
    }, error => {
      console.error(error)
      setErrMsg(error.message)
    })
  }, [])

  const [events, setEvents] = useState(null)
  const [eventIds, setEventIds] = useState(null)
  useEffect(() => {
    setEventIds(null)
    setEvents(null)
    setErrMsg("")

    if(seasonId !== null) {
      const path="/seasons/events"
      const params = {"season_id":seasonId}
      adminApi.get(path, params, results => {
        if (results === null) {
          results = []
        }

        results.sort((a,b) => {
          const now = new Date()
          const aStart = new Date(Date.parse(a.start))
          const bStart = new Date(Date.parse(b.start))
          if (aStart < bStart) {
            if (aStart < now && bStart > now) {
              return 1
            } else {
              return -1
            }
          } else {
            if (aStart > now && bStart < now) {
              return -1
            } else {
              return 1
            }
          }
        })

        setEvents(results)
      }, error => {
        console.error(error)
        setErrMsg(error.message)
      })
    }
  }, [seasonId])

  function seasonIdChanged(seasonId) {
    setSeasonId(seasonId)
    const season = seasons.find(season => season.id === seasonId)
    setSeasonName(season.name)
  }

  if (seasons == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  }

  if (seasonId === null) {
    return (
      <div className='admin-container-fill'>
        <div>
          Season:
        </div>
        <div className='div-right'>
          <SeasonDropdown seasons={seasons} seasonId={seasonId} setSeasonId={seasonIdChanged}/>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='admin-container-fill'>
        <div>
          <div>
            Season:
          </div>
          <div className='div-right'>
            <SeasonDropdown seasons={seasons} seasonId={seasonId} setSeasonId={seasonIdChanged}/>
          </div>
        </div>
        <div className='admin-row'>
          <div>
            Events:
          </div>
          <div className='div-right'>
            <EventMultiSelect events={events} initialEventIds={eventIds} setEventIds={setEventIds} />
          </div>
        </div>
        <div className='div-right'>
          <br/>
          <form onSubmit={event => {
            event.preventDefault()
            setRosterEditorArgs({seasonId:seasonId, seasonName:seasonName, events:getSelectedEvents(events, eventIds)})
          }}>
          <ButtonOrBusy className="btn" text="Add Rosters" loading={loading} disabled={seasonId === null || eventIds === null || eventIds.length === 0} />
          <ErrorMessage errMsg={errMsg}/>
          </form>
        </div>
      <div><input type="checkbox" onClick={event => setSummariesOnly(event.target.checked)}/>Summaries Only</div>
      </div>
    </>
  )
}

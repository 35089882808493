import React, { useState } from 'react'
import { AmplifyAPI } from '../../utils/api'
import ErrorMessage from '../components/ErrorMessage'
import RosterEditOptions from '../components/RosterEditOptions'
import RosterEditor from '../components/RosterEditor'
import {Roster} from '../../utils/roster'

const adminApi = new AmplifyAPI("AdminApi")

function processTeams(data) {
  let players = []
  for (const team of data.teams) {
    if (team.players) {
      for (const player of team.players) {
        players.push({...player, team_id: team.id, team_name: team.name})
      }
    }
  }

  for (const player of data.additional_players) {
    players.push({...player, team_id: null, team_name: "No Team"})
  }

  return players
}

function parseRostersAndAvailability(players, data, eventIds) {
  const eventAvailability = {}
  const eventRosters = {}
  for (const eventId of eventIds) {
    const currEventAvailability = new Map()
    
    for (const cal_entry of data.event_to_cal_entries[eventId]) {
      currEventAvailability.set(cal_entry.person_id, cal_entry.rsvp)
    }

    const currEventRoster = Roster.buildRoster(players, data.event_to_rostered_player_id[eventId])
    
    eventAvailability[eventId] = currEventAvailability
    eventRosters[eventId] = currEventRoster
  }

  return {eventAvailability: eventAvailability, eventRosters: eventRosters}
}

function processEventsRosters(players, results, rosterEditorArgs) {
  const eventIds = []
  for (const event of rosterEditorArgs.events) {
    eventIds.push(event.id)
  }

  const {eventAvailability, eventRosters} = parseRostersAndAvailability(players, results, eventIds)
  for (const event of rosterEditorArgs.events) {
    if (!event.rostered) {
      eventRosters[event.id] = null
    }
  }

  return {
    id: crypto.randomUUID(),
    players: players, 
    eventAvailability: eventAvailability,
    initialEventRosters: eventRosters,
    rosterEditorArgs: rosterEditorArgs
  }
}

export default function Rosters() {
  const [errMsg, setErrMsg] = useState("")
  const [rosterEditorData, setRosterEditorData] = useState([])
  const [summariesOnly, setSummariesOnly] = useState(false)

  function addRosterEditor(rosterEditorArgs) {
    if (rosterEditorArgs.seasonId === null || rosterEditorArgs.seasonId.length === 0 || rosterEditorArgs.events === null || rosterEditorArgs.events.length === 0) {
      return
    }

    const eventIds = []
    for (const event of rosterEditorArgs.events) {
      eventIds.push(event.id)
    }

    const path = "/seasons/teams/players"
    const params = {"season_id":rosterEditorArgs.seasonId}
    adminApi.get(path, params, results => {
      if (results == null) {
        results = []
      }

      console.log(results)

      const players = processTeams(results)
      adminApi.get("/events/roster", {"event_id":eventIds.join(',')}, results => {
        console.log(results)
        const newRosterData = processEventsRosters(players, results, rosterEditorArgs)
        setRosterEditorData([...rosterEditorData, newRosterData])

      }, error => {
        console.error(error)
        setErrMsg(error.message)
      })
    }, error => {
      console.error(error)
      setErrMsg(error.message)
    })
  }

  function handleCloseRosterEditor(id) {
    setRosterEditorData(rosterEditorData.filter(data => data.id !== id))
  }

  function handleRosterSave(id, eventIdToNewRoster, successCB, errCB) {
    console.log(id, eventIdToNewRoster)
    const idx = rosterEditorData.findIndex(data => data.id === id)
    if (idx === -1) {
      errCB("Unable to find roster editor data")
    }

    const rosterEditorArgs = rosterEditorData[idx].rosterEditorArgs
    const players = rosterEditorData[idx].players

    const eventRosters = []
    for (let i = 0; i < rosterEditorData[idx].rosterEditorArgs.events.length; i++) {
      const eventId = rosterEditorData[idx].rosterEditorArgs.events[i].id
      const newRoster = eventIdToNewRoster[eventId]
      let idToPlayerInfo = {}
      newRoster.forEachRostered(player => {
        idToPlayerInfo[player.id] = {
          position: player.position
        }
      })

      eventRosters.push({
        event_id: eventId,
        id_to_player_info: idToPlayerInfo
      })
    }
    
    console.log(eventRosters)
    adminApi.post("/events/roster", {}, {event_rosters:eventRosters}, results => {
      console.log("post /events/roster", results)
     
      const newRosterData = processEventsRosters(players, results, rosterEditorArgs) 
      const data = [...rosterEditorData]
      data[idx] = newRosterData
      setRosterEditorData(data)
      successCB()
    }, error => {
      console.error(error)
      errCB(error.message)
    })
  }

  // render components
  return (
    <section className='admin-content-section'>
      <RosterEditOptions setRosterEditorArgs={addRosterEditor} setSummariesOnly={setSummariesOnly}/>
      <ErrorMessage errMsg={errMsg}/>
      {rosterEditorData.map((data, index) => {
        return (
          <RosterEditor key={data.id} rosterEditorData={data} closeRosterEditor={() => handleCloseRosterEditor(data.id)} saveRosters={handleRosterSave} summariesOnly={summariesOnly}/>
        )
      })}
    </section>
  )
}

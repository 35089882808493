import React, { useState } from 'react'
import LabeledInput from './LabeledInput'
import DatePicker from 'react-datepicker'
import { objectsEqual } from '../../utils/compare'
import { useNavigate } from 'react-router-dom'

export default function DynamicForm({fields, initialValue, handleSubmit}) {
  const navigate = useNavigate()

  if (initialValue === undefined) {
    initialValue = {}
  }
  
  initialValue.start = ""
  const fieldVals = {}
  for (let i = 0; i < fields.fields.length; i++) {
    const current = fields.fields[i]
    fieldVals[current.name] = ""
  }

  if (initialValue) {
    for (let i = 0; i < fields.fields.length; i++) {
      const currentName = fields.fields[i].name
      const initialFieldValue = initialValue[currentName]

      if (initialFieldValue) {
        fieldVals[currentName] = initialFieldValue
      } else if (fields.fields[i].type === "date") {
        fieldVals[currentName] = new Date()
      }
    }
  }

  const initialFieldsValid = {}
  for (let i = 0; i < fields.fields.length; i++) {
    const current = fields.fields[i]
    initialFieldsValid[current.name] = current.required ? fieldVals[current.name].length !== 0 : true
  }

  const [vals, setVals] = useState(fieldVals)
  const [fieldsValid, setFieldValid] = useState(initialFieldsValid)

  function allValid() {
    for (let i = 0; i < fields.fields.length; i++) {
      const current = fields.fields[i].name
      if (!fieldsValid[current]) {
        return false
      }
    }

    return true
  }

  function handleValueChanged(fieldName, valid, value) {
    const newValid = {...fieldsValid}
    const newVals = {...vals}

    newValid[fieldName] = valid
    newVals[fieldName] = value

    if (!objectsEqual(newVals, vals)) {
      setVals(newVals)

    }
    if (!objectsEqual(newValid, fieldsValid)) {
      setFieldValid(newValid)
    }
  }

  return (
    <form onSubmit={event => handleSubmit(event)}>
      {fields.fields.map(formField => {
        let value = vals[formField.name]

        if (formField.type === "hidden") {
          return (
            <input key={formField.name} type="hidden" id={formField.name} name={formField.name} value={value} />
          )
        } else if(formField.type === "select") {
          if (formField.options == null) {
            return null
          }

          let label = formField.display + ":"
          if (formField.required) {
            label = "*" + label
          }

          return (
            <div key={formField.name} className="admin-row">
              <div>{label}</div>
              <div>
                <select className='inpt' id={formField.name} name={formField.name} defaultValue={value} onChange={event => handleValueChanged(formField.name, true, event.target.value)}>
                  {formField.options.map(option => {
                    return (
                      <option key={formField.name + '-' + option.id}value={option.id}>{option.option}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          )
        } else if (formField.type === "const") {
          return (
            <div key={formField.name} className="admin-row">
              <div>{formField.display}: {formField.constVal}</div>
            </div>
          )
        }else if(formField.type === "date") {
          let label = formField.display + ":"
          if (formField.required) {
            label = "*" + label
          }

          return (
            <div key={formField.name} className="admin-row">
              <div>{label}
                <DatePicker id={formField.name} className="inpt" selected={value} onChange={event => handleValueChanged(formField.name, true, event)}/>
              </div>
            </div>
          )
        } else {
          let label = formField.display + ":"
          if (formField.required) {
            label = "*" + label
          }

          return (
            <div key={formField.name} className="admin-row">
                <LabeledInput name={formField.name} label={label} type={formField.type} maxLength={formField.maxLen} validate={formField.validator} delay={500} handleChange={(valid, value) => handleValueChanged(formField.name, valid, value)} initialValue={value}/>
            </div>
          )
        }
      })}
      <div className="admin-row div-right">
        <button className="btn btn-cancel" onClick={event => {
          event.preventDefault()
          navigate(-1)
        }}>Cancel</button>
        <button className="btn" disabled={!allValid()}>Submit</button>
      </div>
    </form>
  )
}

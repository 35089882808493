import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom'

export default function AdminNav() {
  const navigate = useNavigate();

  function logout() {
    console.log("logging out")
    Auth.signOut().then(() => navigate("/"))
  }

  const [topNav, setTopNav] = useState(window.innerWidth < 800)
  window.addEventListener('resize', () =>{
    const newTopNav = window.innerWidth < 800
    if (newTopNav !== topNav) {
      setTopNav(newTopNav)
    }
  })

  let containerClass = 'adminnav-container'
  let navlinkClass = 'adminnav-navlink-div'
  if (topNav) {
    containerClass = 'adminnav-top-container'
    navlinkClass = 'adminnav-top-navlink-div'
  }

  return (
    <div className={containerClass}>
      <div className={navlinkClass}><NavLink to="seasons">Seasons</NavLink></div>
      <div className={navlinkClass}><NavLink to="players">Players</NavLink></div>
      <div className={navlinkClass}><NavLink to="email">Email</NavLink></div>
      <div className={navlinkClass}><NavLink to="locations">Locations</NavLink></div>
      <div className={navlinkClass}><NavLink to="rosters">Rosters</NavLink></div>
      <div className={navlinkClass}><NavLink to="invites">Invites</NavLink></div>
      <div className={navlinkClass}><NavLink to="import_players">Import Players</NavLink></div>
      <div className={navlinkClass}><NavLink onClick={() => logout()}>Logout</NavLink></div>
      {/*<div className='adminnav-navlink-div'><NavLink to="usalax">USA Lacrosse</NavLink></div>*/}
      {/*<div className='adminnav-navlink-div'><NavLink to="events">Events</NavLink></div>*/}
      {/*<div className='adminnav-navlink-div'><NavLink to="guardians">Guardians</NavLink></div>*/}
    </div>
  )
}

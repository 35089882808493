import React from 'react'
import { RiseLoader } from 'react-spinners'
import { dateStrToDateAndTimeDisplayStrings } from '../../utils/date'
import Select from 'react-select'

export default function EventMultiSelect({events, initialEventIds, setEventIds}) {
  if (events == null || events.length === 0) {
      return (
        <RiseLoader loading={true} size="25px" color="#36a7d7"/>
      )
  }

  let options = []
  for (const event of events) {
    let [date, time] = dateStrToDateAndTimeDisplayStrings(event.start)

    if (time[1] === ':') {
      time = '0' + time
    }

    const optionValue = [date, time, event.title].join(' ')
    options.push({label: optionValue, value: event.id})
  }

  return (
    <Select options={options} inputProps={{readOnly: true}}isSearchable={false} isMulti={true} onChange={e => { 
      const newEventIds = new Set()
      for (const option of e) {
        newEventIds.add(option.value)
      }

      setEventIds(newEventIds) 
    }} />
  )
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI, formFieldsToObject } from '../../utils/api'
import DynamicForm from '../components/DynamicForm'
import { locationFormFields } from './FormDefs'

export function getHandleLocationSubmit(adminApi, navigate) {
  return function handleLocationSubmit(event) {
    event.preventDefault()
    const data = formFieldsToObject(locationFormFields, event)
    adminApi.post("/locations", {}, data, results => {
      console.log("POST /locations results", results)
      navigate('/admin/locations')
    }, error => console.error(error))
  }
}

const adminApi = new AmplifyAPI("AdminApi")

export default function LocationEdit() {
  const {id} = useParams()
  const [location, setLocation] = useState(null)

  const navigate = useNavigate()
  const handleLocationSubmit = getHandleLocationSubmit(adminApi, navigate)

  useEffect(() => {
    const path = "/locations"
    adminApi.get(path, {"location_id":id}, results => {
      console.log("GET location", results)
      setLocation(results)
    }, error => console.error(error))
  }, [id])

  if (location == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    return (
      <section className='admin-content-section'>
        <div className='admin-container-narrow'>
          <h3 className='div-center'>Location</h3>
          <DynamicForm fields={locationFormFields} initialValue={location} handleSubmit={handleLocationSubmit}/>
        </div>
      </section>
    )
  }
}

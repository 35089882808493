import React, { useState, useEffect } from 'react'
import { ParsePlayer } from '../../utils/wire';
import { TitleCase, AgeForDOB } from '../../utils/formatting'
import DynamicTable from '../components/DynamicTable';
import { RiseLoader } from 'react-spinners';
import { AmplifyAPI } from '../../utils/api';


const adminApi = new AmplifyAPI("AdminApi")

export default function UsaLax() {

  const [players, setPlayers] = useState(null)
  useEffect(() => {
    adminApi.get("/players", null, results => {
      let newPlayers = []
      if (results.players != null) {
        for (let i = 0; i < results.players.length; i++) {
            newPlayers.push(ParsePlayer(results.players[i])) 
        }
      }
      console.log("players", newPlayers)

      setPlayers(newPlayers)
    }, error => console.error(error))
  },[])

  useEffect(() => {
    if (players == null) {
      return
    }
  }, [players])

  if (players == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  }

  function getValidStr(player, now) {
    if (player.usalax_mem_number === null || player.usalax_mem_number.length === 0) {
      return "False"
    }

    if (player.usalax_zip === null || player.usalax_zip.length === 0) {
      return "False"
    }
    
    if (player.usalax_mem_expiration === null && player.usalax_mem_expiration.length === 0) {
      return "-"
    }

    let expDate = new Date(Date.parse(player.usalax_mem_expiration))
    if (expDate < now) {
      return "False"
    } else {
      return "True"
    }
  }

  const headers = ["Is Valid", "Name", "USA LAX #", "USA LAX Expiration", "USA LAX Zip", "Grad Year", "Age"]
  const rows = []
  const now = Date.now()
  for (let i = 0; i < players.length; i++) {
    const player = players[i]
    const isValid = getValidStr(player, now)
    rows.push({
      url: "../player/" + player.id,
      cols: [
        isValid,
        TitleCase([player.first,player.last].join(' ')), 
        player.usalax_mem_number,
        player.usalax_mem_expiration,
        player.usalax_zip,
        player.grad_year,
        AgeForDOB(player.dob), 
      ],
    })

    function compareStrsNullIsMore(s1, s2) {
      if (s1 === s2) {
        return 0
      } else if (s1 === null && s2 !== null) {
        return -1
      } else if (s2 === null && s1 !== null) {
        return 1
      } else if (s1 < s2) {
        return -1
      } else {
        return 1
      }
    }
    
    rows.sort((r1,r2) => {
      let res = compareStrsNullIsMore(r1[2], r2[2])
      if (res !== 0) {
        return res
      }

      res = compareStrsNullIsMore(r1[3], r2[3])
      if (res !== 0) {
        return res
      }

      if (r1[1] !== r2[1]) {
        return r1[1] < r2[1] ? -1 : 1
      } else {
        return 0
      }
    })
  }
  
  return (
    <section className='admin-content-section'>
      <div className='admin-container'>
        <div className="admin-row">
          <DynamicTable headers={headers} rows={rows}/>
        </div>
      </div>
    </section>
  )
}

import React, { useState } from 'react'
import FileInput from '../components/FileInput'
import CsvHeaderMapper, { CalcMapping } from '../components/CsvHeaderMapper'
import { CsvReader } from '../../utils/csvreader'
import CsvDisplayTable from '../components/CsvDisplayTable'

export default function CsvImport({required, optional, expected, onImport}) {
  function handleFileChanged(event) {
    const reader = new CsvReader(event.target.files[0])
    reader.Read(handleFileRead)
  }

  const [headers, setHeaders] = useState(null)
  const [mapping, setMapping] = useState(null)
  const [rows, setRows] = useState(null)
  function handleFileRead(reader) {
    const readErr = reader.Error()
    if (readErr !== null) {
      console.error("Error reading file", readErr)
      return
    }
    
    const newHeaders = reader.HeadersLower()
    setHeaders(newHeaders)
    setMapping(CalcMapping(newHeaders, required, optional, expected))
    setRows(reader.Rows())
  }

  function handleHeaderMappingChanged(newMapping) {
    setMapping(newMapping)
  }

  function readyForImport(required, mapping) {
    if (mapping !== null) {
      for (let i = 0; i < required.length; i++) {
        if (!mapping.has(required[i])) {
          return false
        } else {
          const val = mapping.get(required[i])
          if (val === null || val === "") {
            return false
          }
        }
      }

      return true
    } 

    return false
  }

  return (
    <>
      <section className='admin-content-section'>
        <div className='admin-container'>
          <div className='div-left admin-row' >
            <FileInput onFileChanged={handleFileChanged}/>
          </div>
        </div>
      </section>
      <CsvHeaderMapper headers={headers} required={required} optional={optional} mapping={mapping} onMappingChanged={handleHeaderMappingChanged} />
      <CsvDisplayTable rows={rows} headers={headers} mapping={mapping} enableImport={readyForImport(required, mapping)} onImport={onImport}/>
    </>
  )
}

import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AmplifyAPI } from '../../utils/api'
import DynamicForm from '../components/DynamicForm'
import { teamFormFields } from './FormDefs'
import { getHandleTeamSubmit } from './TeamEdit'

export default function TeamCreate() {
  const adminApi = new AmplifyAPI("AdminApi")
  const navigate = useNavigate()
  const {season_id} = useParams()

  console.log(season_id)

  return (
    <section className='admin-content-section'>
      <div className='admin-container-narrow'>
        <h3 className='div-center'>Location</h3>
        <DynamicForm fields={teamFormFields} initialValue={{season_id:season_id}} handleSubmit={getHandleTeamSubmit(season_id, adminApi, navigate)}/>
      </div>
    </section>
  ) 
}

import React from 'react'
import { Link } from 'react-router-dom'
import { SortSeasonsByName } from '../../utils/domain'

export default function SeasonsList({seasons, header}) {
  SortSeasonsByName(seasons)

  return (
    <div className='admin-container-narrow'>
      {header}
      {seasons.map( season => {
        return (
          <div className='admin-row' key={season.id}>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Link to={"../season/" + season.id} >
              {season.name}
            </Link>
          </div>
        )
      })}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI } from '../../utils/api'
import { TitleCase } from '../../utils/formatting'
import DynamicTable from '../components/DynamicTable'

const adminApi = new AmplifyAPI("AdminApi")

export default function Locations() {
  const navigate = useNavigate()

  const [locations, setLocations] = useState(null)

  useEffect(() => {
    const path = "/locations"
    adminApi.get(path, null, results => {
      console.log(results)
      if (results == null) {
        results = []
      }
      setLocations(results)
    }, error => console.error(error))
  }, [])

  if (locations == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    locations.sort((l1, l2) => l1.name.localeCompare(l2.name))
    console.log("locations:", locations)
    const headers = ["Name", "Address", "City", "State", "Zip", "Url"]
    const rows = []
    for (let i = 0; i < locations.length; i++) {
      const location = locations[i]
      let row = {
        url: "../location/" + location.id + "/edit",
        cols: [
          TitleCase(location.name),
          TitleCase(location.address),
          TitleCase(location.city),
          location.state.toUpperCase(),
          location.zip,
          location.url,
        ],
      }
      rows.push(row)
    }

    return (
      <section className='admin-content-section'>
        <div className='admin-container'>
          <div className="admin-row">
          <DynamicTable headers={headers} rows={rows}/>
          <br/>
          <br/>
          <div className="div-right">
          <button className='btn' onClick={() => navigate('/admin/location/new')}>Create</button>
          </div>
        </div>
      </div>
    </section>
    )
  }
}

import React, { useEffect, useState } from 'react'
import LabeledInput from '../components/LabeledInput'
import { ValidateEmail } from '../../utils/validation'
import UserMultiSelect from '../components/UserMultiSelect'
import { AmplifyAPI } from '../../utils/api'
import { ParsePlayer } from '../../utils/wire'
import InviteList from '../components/InviteList'

const adminApi = new AmplifyAPI("AdminApi")

export default function Invites() {
  const [email, setEmail] = useState("")
  function handleEmailChanged(valid, val) {
    setEmail(val)
  }

  const [selectedIds, setSelectedIds] = useState([])
  function handleChangeUsers(e) {
    console.log(e)

    const ids = [...selectedIds]
    for (let i = 0; i < e.length; i++) {
      const id = e[i].value
      ids.push(id)
    }

    setSelectedIds(ids)
  }

  const [players, setPlayers] = useState(null)
  useEffect(() => {
    adminApi.get("/players", null, results => {
      let players = []
      if (results.players != null) {
        for (let i = 0; i < results.players.length; i++) {
          players.push(ParsePlayer(results.players[i])) 
        }
      }
      console.log("players", players)
      setPlayers(players)
    }, error => console.error(error))
  },[])

  const [invites, setInvites] = useState(null)
  useEffect(() => {
    adminApi.get("/invites", null, results => {
      console.log("invites", results)
      setInvites(results)
    }, error => console.error(error))
  },[])

  function handleSubmit(e) {
    e.preventDefault()
    const body = {invites:[{email: email, persons: selectedIds.join(",")}]}
    console.log("handleSubmit", body)
    adminApi.post("/invites", null, body, results => {
      console.log("results", results)
      setInvites(results)
    }, error => console.error(error))
  }

  return (
    <section className='admin-content-section'>
      <div className='admin-container'>
        <form onSubmit={handleSubmit}>
          <div className="admin-row">
            <LabeledInput name="email" label="Email:" initialValue={email} type="email" maxLength={128} validate={ValidateEmail} handleChange={handleEmailChanged} delay={500} />
          </div>
          <div className='admin-row'>
            Players:
            <UserMultiSelect players={players} onChange={handleChangeUsers} selected={selectedIds} />
          </div>
          <div className='admin-row div-right'>
            <button type="submit" className="btn">Create New Invite</button>
          </div>
        </form>
      </div>
      <div className='admin-container'>
        <div className='admin-row div-center'>
          <h3>Invites</h3>
        </div>
        <div className='admin-row'>
          <InviteList invites={invites} />
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import LabeledInput from '../components/LabeledInput'
import EmailEditor from 'react-email-editor'

export default function Email() {
  return (
    <section className='admin-content-section'>
      <div className='admin-container'>
        <div className='admin-row'>
          <label>To:</label>
          <div className='div-right'>
            <span>Boys 2/3 Winter 2023</span>
            <span><button className='btn btn-cancel email-add-remove-btn-margin'>x</button></span>
          </div>
          <div className='div-right'>
            <span>Boys 4/5 Winter 2023</span>
            <span><button className='btn btn-cancel email-add-remove-btn-margin'>x</button></span>
          </div>
          <div className='div-right'>
            <span>Girls All Winter 2023</span>
            <span><button className='btn btn-cancel email-add-remove-btn-margin'>x</button></span>
          </div>
        </div>
        <div className='admin-row'>
          <label>Add Recipients:</label>
          <div className='div-right'>
            <span>
              <select className='email-add-recipient-select'>
                <option>Boys 7-8 Winter 2023</option>
                <option>Boys All Winter 2023</option>
                <option>Girls K/1 Winter 2023</option>
                <option>Girls 2/3 Winter 2023</option>
                <option>Girls 4/5 Winter 2023</option>
                <option>Girls 7/8 Winter 2023</option>
              </select>
            </span>
            <span><button className='btn btn-add email-add-remove-btn-margin'>+</button></span>
          </div>
        </div>
        <div className="admin-row">
          <LabeledInput name="subject" label="Subject:" type="text" maxLength={128} delay={500}/>
        </div>
        <div className="admin-row">
          <div><label>Email Body:</label></div>
          <div><EmailEditor /></div>
        </div>
        <div className='adminemail-row div-right'>
          <button className='btn'>Send</button>
        </div>
      </div>
    </section>
  )
}

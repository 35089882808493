import React from 'react'

export default function RosterSummary({eventName,  players, roster, availability}) {
  const rosteredIds = roster.rostered_ids

  let confirmedGoaliesOnRoster = 0
  let confirmedOnRoster = 0
  let nonrespondersOnRoster = 0
  let unavailableOnRoster = 0
  let confirmedNotOnRoster = 0
  for (const player of players) {
    const unavailable = availability.get(player.id) === "no"
    const confirmed = availability.get(player.id) === "yes"
    const nonresponder = availability.get(player.id) === "none"
    const onRoster = rosteredIds.has(player.id)
    const playsGoalie = player.position !== "nongoalie"

    confirmedGoaliesOnRoster += (onRoster && confirmed && playsGoalie) ? 1 : 0
    confirmedOnRoster += (onRoster && confirmed) ? 1 : 0
    nonrespondersOnRoster += (onRoster && nonresponder) ? 1 : 0
    unavailableOnRoster += (onRoster && unavailable) ? 1 : 0
    confirmedNotOnRoster += (!onRoster && confirmed) ? 1 : 0
  }

  return (
    <>
      <div className="dynamictable-table-container-div div-center">
        {eventName}<br/><br/>
        <table className="dynamictable-table">
          <tbody>
            <tr className="dynamictable-row">
              <td className="dynamictable-row-val">Confirmed Goalies On Roster</td>
              <td className="dynamictable-row-val">{confirmedGoaliesOnRoster}</td>
            </tr>
            <tr className="dynamictable-row">
              <td className="dynamictable-row-val">Confirmed On Roster</td>
              <td className="dynamictable-row-val">{confirmedOnRoster}</td>
            </tr>
            <tr className="dynamictable-row">
              <td className="dynamictable-row-val">Nonresponders on Roster</td>
              <td className="dynamictable-row-val">{nonrespondersOnRoster}</td>
            </tr>
            <tr className="dynamictable-row">
              <td className="dynamictable-row-val">Unavailable on Roster</td>
              <td className="dynamictable-row-val">{unavailableOnRoster}</td>
            </tr>
            <tr className="dynamictable-row">
              <td className="dynamictable-row-val">Available but not Rostered</td>
              <td className="dynamictable-row-val">{confirmedNotOnRoster}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

import { ParsePlayer } from "./wire"

export class Roster {
  constructor(idToPerson, rostered_ids, nonrostered_ids) {
    this.idToPerson = idToPerson
    this.rostered_ids = rostered_ids
    this.nonrostered_ids = nonrostered_ids
  }

  static buildRoster(personnel, rostered_ids) {
    let rostered_id_set = null
    if (rostered_ids && rostered_ids.length > 0) {
      rostered_id_set = new Set(rostered_ids)
    } else {
      rostered_id_set = new Set()
    }

    let nonrostered_ids = new Set()
    let idToPerson = new Map()

    if (personnel != null) {
      for (let i = 0; i < personnel.length; i++) {
        const person = ParsePlayer(personnel[i])
        const id = personnel[i].id
        if (!rostered_id_set.has(id)) {
          nonrostered_ids.add(id)
        }

        idToPerson.set(id, person)
      }
    }

    return new Roster(idToPerson, rostered_id_set, nonrostered_ids)
  }

  forEachRostered(cb) {
    return this.forEachPlayerIn(this.rostered_ids, cb)
  }

  forEachNonrostered(cb) {
    return this.forEachPlayerIn(this.nonrostered_ids, cb)
  }

  forEachPlayerIn(idSet, cb) {
    console.log(idSet)
    idSet.forEach(id => {
      console.log(id)
      let person = this.idToPerson.get(id)
      console.log(person)
      if (person) {
        cb(person)
      }
    })
  }

  addPlayerToRoster(id) {
    if (this.nonrostered_ids.has(id) && !this.rostered_ids.has(id)) {
      let rostered_copy = new Set(this.rostered_ids)
      let nonrostered_copy = new Set(this.nonrostered_ids)

      rostered_copy.add(id)
      nonrostered_copy.delete(id)
      return new Roster(this.idToPerson, rostered_copy, nonrostered_copy)
    }

    return this
  }

  removePlayerFromRoster(id) {
    if (!this.nonrostered_ids.has(id) && this.rostered_ids.has(id)) {
      let rostered_copy = new Set(this.rostered_ids)
      let nonrostered_copy = new Set(this.nonrostered_ids)

      rostered_copy.delete(id)
      nonrostered_copy.add(id)
      return new Roster(this.idToPerson, rostered_copy, nonrostered_copy)
    }

    return this
  }

  diffRoster(other) {
    const onlyInThis = []
    this.rostered_ids.forEach(id => {
      if (!other.rostered_ids.has(id)) {
        onlyInThis.push(id)
      }
    })

    const onlyInOther = []
    other.rostered_ids.forEach(id => {
      if (!this.rostered_ids.has(id)) {
        onlyInOther.push(id)
      }
    })

    return [onlyInThis, onlyInOther]
  }

  isOnTheRoster(id) {
    return this.rostered_ids.has(id)
  }

  getRosteredIds() {
    return Array.from(this.rostered_ids)
  }
}
import React from 'react';
import './pages/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';

import Email from './pages/admin/Email'
import Event from './pages/admin/Event'
import EventsCreate from './pages/admin/EventsCreate';
import EventDelete from './pages/admin/EventDelete';
import EventEdit from './pages/admin/EventEdit';
import AdminNav from './pages/components/AdminNav';
import Locations from './pages/admin/Locations';
import LocationCreate from './pages/admin/LocationCreate';
import LocationEdit from './pages/admin/LocationEdit';
import PasswordReset from './pages/PasswordReset';
import Players from './pages/admin/Players'
import Player from './pages/admin/Player'
import ProtectedRoute from './pages/components/ProtectedRoute';
import PublicRoute from './pages/components/PublicRoute'
import Season from './pages/admin/Season'
import SeasonPlayers from './pages/admin/SeasonPlayers'
import SeasonAssignPlayersToTeams from './pages/admin/SeasonAssignPlayersToTeams'
import Seasons from './pages/admin/Seasons'
import SeasonCreate from './pages/admin/SeasonCreate';
import SeasonEdit from './pages/admin/SeasonEdit';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import SignOut from './pages/SignOut';
import TeamCreate from './pages/admin/TeamCreate';
import TeamEdit from './pages/admin/TeamEdit';
import UsaLax from './pages/admin/UsaLax'
import UserHome from './pages/user/UserHome';
import { RiseLoader } from 'react-spinners';
import PlayerEdit from './pages/admin/PlayerEdit';
import Rosters from './pages/admin/Rosters';
import { initFeatures } from './utils/features';
import UserRoster from './pages/user/UserRoster';
import Invites from './pages/admin/Invites';
import PlayerImport from './pages/admin/PlayerImport';

initFeatures()

Amplify.configure(awsconfig);

function App() {
  return (
    <>
    <Routes>
      <Route path="/user/*" element={<ProtectedRoute />} />
      <Route path="/admin/*" element={<><ProtectedRoute /><AdminNav/></>} />
      <Route path="/*" element={<PublicRoute />} />
    </Routes>

    <Routes>
      <Route path="/user">
        <Route path="" element={<UserHome />} />
        <Route path="event/:event_id/roster" element={<UserRoster />} />
      </Route>
      <Route path="/admin">
        <Route index element={<Navigate to="seasons" />} />
        <Route path="email" element={<Email/>} />
        <Route path="seasons" element={<Seasons/>} />
        <Route path="season/new" element={<SeasonCreate/>} />
        <Route path="season/:id" element={<Season/>} />
        <Route path="season/:id/edit" element={<SeasonEdit/>} />

        <Route path="season/:season_id/players" element={<SeasonPlayers/>} />
        <Route path="season/:season_id/players/assign" element={<SeasonAssignPlayersToTeams/>} />
        <Route path="season/:season_id/team/new" element={<TeamCreate/>} />
        <Route path="season/:season_id/team/:team_id/edit" element={<TeamEdit/>} />
        <Route path="season/:season_id/events/new" element={<EventsCreate/>} />
        <Route path="season/:season_id/event/:event_id" element={<Event/>} />
        <Route path="season/:season_id/event/:event_id/edit" element={<EventEdit/>} />
        <Route path="season/:season_id/event/:event_id/delete" element={<EventDelete/>} />
        <Route path="players" element={<Players/>} />
        <Route path="player/:id" element={<Player/>} />
        <Route path="player/:id/edit" element={<PlayerEdit/>} />
        <Route path="locations" element={<Locations/>} />
        <Route path="location/new" element={<LocationCreate/>} />
        <Route path="location/:id/edit" element={<LocationEdit/>} />
        <Route path="usalax" element={<UsaLax/>} />
        <Route path="rosters" element={<Rosters/>} />
        <Route path="invites" element={<Invites/>} />
        <Route path="import_players" element={<PlayerImport />} />
      </Route>
      <Route path="/" >
        <Route index element={<Navigate to="signin" />} />
        <Route path="signup/:encoded_signup_params" element={<SignUp />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="signout" element={<SignOut />} />
        <Route path="reset" element={<PasswordReset />} />
        <Route path="load" element={<RiseLoader loading={true} size="25px" color="#36a7d7"/>} />
      </Route>
    </Routes>
    </>
  );
}

export default App;

import React from 'react'
import Select from 'react-select'
import { TitleCase } from '../../utils/formatting'

export default function UserMultiSelect({players, onChange, selected}) {
  if (players == null) {
    return null
  }

  players.sort((a,b) => {
    if (a.last < b.last) {
      return -1
    } else if (a.last > b.last) {
      return 1
    } else {
      if (a.first < b.first) {
        return -1
      } else if (a.first > b.first) {
        return 1
      } else {
        return 0
      }
    }
  })

  let options = []
  for (const player of players) {
    options.push({value: player.id, label: TitleCase(player.last + ', ' + player.first)})
  }

  console.log(players)
  return (
    <Select defaultValues={selected} options={options} inputProps={{readOnly: true}} isSearchable={true} isMulti={true} onChange={onChange} />
  )
}

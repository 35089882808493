import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI } from '../../utils/api'
import { TitleCase } from '../../utils/formatting'
import { ParsePlayer } from '../../utils/wire'
import ButtonOrBusy from '../components/ButtonOrBusy'
import DynamicTable from '../components/DynamicTable'

const adminApi = new AmplifyAPI("AdminApi")

export default function SeasonPlayers() {
  const [initialPlayers, setInitialPlayers] = useState(null)
  const [busy, setBusy] = useState(false)
  const [players, setPlayers] = useState([])
  const [memNumbers, setMemNumbers] = useState("")
  const [missingIds, setMissingIds] = useState("")

  const {season_id} = useParams()

  useEffect(() => {
    adminApi.get("/seasons/teams/players", {season_id:season_id}, results => {  
      const playerLists = []
      if (results.teams != null) {
        for (let i = 0; i < results.teams.length; i++) {
          if (results.teams[i].players != null) {
            playerLists.push(results.teams[i].players)
          }
        }
      }

      if (results.additional_players != null) {
        playerLists.push(results.additional_players)
      }

      const listOfPlayers = []
      for (let i = 0; i < playerLists.length; i++) {
        const list = playerLists[i]
        for (let j = 0; j < list.length; j++) {
            const player = list[j]
            listOfPlayers.push(ParsePlayer(player))
        }
      }

      console.log("initial players", listOfPlayers)
      setInitialPlayers(listOfPlayers)
      setPlayers(listOfPlayers)
    }, error => console.error(error))
  },[season_id])

  function memNumbersUpdated(e) {
    const val = e.target.value
    // if the last character is not a comma or a number then remove it
    if (val.length > 0 && val[val.length - 1] !== "," && isNaN(val[val.length - 1])) {
      setMemNumbers(val.substring(0, val.length - 1))
    } else {
      setMemNumbers(e.target.value)
    }
  }

  function processPlayers(toAdd) {
    let newPlayers = [...players]
    for (let i = 0; i < toAdd.length; i++) {
      let player = toAdd[i]
      if (newPlayers.find(p => p.id === player.id) === undefined) {
        newPlayers.push(ParsePlayer(player))
      } 
    }

    setPlayers(newPlayers)
  }

  function updatePlayers() {
    setBusy(true)

    const ids = []
    for (let i = 0; i < players.length; i++) {
      ids.push(players[i].id)
    }

    const body = {
      season_id: season_id,
      player_ids: ids
    }
    adminApi.post("/seasons/players", {}, body, results => {
      console.log("success")
    }, error => {
      console.error(error)
    }, () => setBusy(false))
  }

  function processMemNumbers(e) {
    e.preventDefault()

    const tokens = e.target[0].value.split(",")
    const valid = []
    for (let i = 0; i < tokens.length; i++) { 
      let token = tokens[i].trim()
      if (token.length > 0) {
        valid.push(token.padStart(12, "0"))
      }
    }

    if (valid.length > 0) {
      setBusy(true)

      adminApi.get("/players", {lax_ids:valid.join(",")}, results => {
        console.log("GET /players", results)

        let missingIds = []
        if (results.players != null) {
          console.log("players", results.players)
          for (let i = valid.length-1; i >= 0; i--) {
            if (!results.players.find(p => valid[i] === p.usalax_mem_number)) {
              missingIds.push(valid[i])
            }
          }
        } else {
          missingIds = [...valid]
        }

        let missingIdsStr = ""
        if (missingIds.length > 0) {
          missingIdsStr = "Unknown IDs: " + missingIds.join(", ")
        }

        setMissingIds(missingIdsStr)
        setBusy(false)
        setMemNumbers("")
        processPlayers(results.players)
      }, error => {
        setBusy(false)
      })
    }
  }

  function getRemoveButton(id) {
    return(
      <button className='btn btn-cancel' onClick={event => {
        event.preventDefault()
        const newPlayers = players.filter(p => p.id !== id)
        setPlayers(newPlayers)
      }}>x</button>
    )
  }

  const tableHeaders = ["Name", "Usa Lax", "Expires", "Grad Year", "Position", "Remove"]
  const tableRows = []
  for (let i = 0; i < players.length; i++) {
    const player = players[i]
    let row = {
      cols: [
        TitleCase([player.first, player.last].join(' ')),
        player.usalax_mem_number,
        player.usalax_mem_expiration,
        player.grad_year,
        TitleCase(player.position),
        getRemoveButton(player.id)
      ],
    }
    tableRows.push(row)
  }

  if (initialPlayers == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    return (
      <section className='admin-content-section'>
        <div className='admin-container'>
          <div className="admin-row">
            <form onSubmit={processMemNumbers}>
              <div>USA LAX Membership Numbers
                <input className='inpt' type='text' placeholder='Comma separated list of numbers' onChange={memNumbersUpdated} value={memNumbers}/>
                {missingIds}
                <div className='div-right'>
                <br/>
                  <ButtonOrBusy className='btn' text='Add' busy={busy}/>
                </div>
              </div>
            </form>
          </div>
        </div>
        <DynamicTable headers={tableHeaders} rows={tableRows} />
        <form onSubmit={event => {
              event.preventDefault()
              updatePlayers()
            }}>
          <div className='admin-row div-right'>
            <ButtonOrBusy className='btn' text='Save' busy={busy}/>
          </div>
        </form>
      </section>
    )
  }
}

import { useEffect } from 'react'
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export default function SignOut() {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("logging out")
    Auth.signOut().finally(() => navigate("/"))
  }, [navigate])

  return null
}

import React, { useState } from 'react'
import CsvImport from '../components/CsvImport'
import { AmplifyAPI } from '../../utils/api'
import { RiseLoader } from 'react-spinners'
import ErrorMessage from '../components/ErrorMessage'
import DynamicTable from '../components/DynamicTable'

const adminApi = new AmplifyAPI("AdminApi")

export default function PlayerImport() {
  const importStates = {
    setup: 0,
    sendForPreview: 1,
    preview: 2,
    sendForImport: 3,
    error: 4,
    success: 5
  }

  const required=["usalax_mem_number", "usalax_mem_expiration", "dob", "first", "last", "email", "phone", "zip"]
  const optional=["middle"]
  const expected=new Map([
    ["usl member number","usalax_mem_number"],
    ["member expiration date","usalax_mem_expiration"],
    ["date of birth","dob"],
    ["first name","first"],
    ["middle name","middle"],
    ["last name","last"],
    ["guardian email","email"],
    ["guardian phone","phone"],
    ["postal code","zip"]
  ])

  const [errMsg, setErrMsg] = useState(null)
  const [importState, setImportState] = useState(importStates.setup)
  const [previewRows, setPreviewRows] = useState(null)
  function handleImport(rows) {
    setImportState(importStates.sendForPreview)
    const path = "/players/importpreview"
    const data = {
      "rows": rows,
    }
    adminApi.post(path, {}, data, results => {
      console.log(results)
      setPreviewRows(results.rows)
      setImportState(importStates.preview)
    }, error => {
      setErrMsg(error.message)
      setImportState(importStates.error)
    })
  }

  const [importResults, setImportResults] = useState(null)
  function handleImportPreviewed(event) {
    setImportState(importStates.sendForPreview)
    const path = "/players/import"
    const data = {
      "rows": previewRows,
    }
    adminApi.post(path, {}, data, results => {
      console.log(results)
      setImportResults(results)
      setImportState(importStates.success)
    }, error => {
      setErrMsg(error.message)
      setImportState(importStates.error)
    })
  }

  if (importState === importStates.setup) {
    return (
      <CsvImport required={required} optional={optional} expected={expected} onImport={handleImport}/>
    )
  } else if (importState === importStates.sendForPreview || importState === importStates.sendForImport) {
    return (
      <RiseLoader loading={true} size="25px" color="#36a7d7" />
    )
  } else if (importState === importStates.preview) {
    // loop over the first preview row's fields and create a table header
    const tableHeaders = []
    for (var prop in previewRows[0]) {
      tableHeaders.push(prop)
    }

    // loop over the preview rows and create a table row for each
    const tableRows = []
    for (let i = 0; i < previewRows.length; i++) {
      const row = previewRows[i]
      console.log(row)
      const cols = []
      for (let j = 0; j < tableHeaders.length; j++) {
        cols.push(row[tableHeaders[j]])
      }
      tableRows.push({cols: cols})
    }

    return (
      <section className="admin-content-section">
        <h1>{previewRows.length} new player(s) will be imported.</h1>
        <form className='full-width' onSubmit={event => handleImportPreviewed(event)}>
          <button className="btn btn-primary full-width" type="submit">Import</button>
        </form>
        <DynamicTable headers={tableHeaders} rows={tableRows}/>
      </section>
    )
  } else if (importState === importStates.error) {
    return (
      <ErrorMessage text={errMsg}/>
    )
  } else if (importState === importState.success) {
    return (
      <section className="admin-content-section">
        <h1>Imported {importResults.players_imported} new player(s).</h1>
        <h1>Sent {importResults.invites_sent} invite(s).</h1>
      </section>
    )
  }
}

import React, { useState, useEffect } from 'react'
import { API } from 'aws-amplify';
import './css/App.css'
import { Buffer } from 'buffer';
import LabeledInput from './components/LabeledInput';
import ErrorMessage from './components/ErrorMessage';
import PhoneNumberInput from './components/PhoneNumberInput';
import { ValidateEmail, ValidateStrFunc } from '../utils/validation';
import { Auth } from 'aws-amplify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {AmplifyAPI} from '../utils/api'

const publicApi = new AmplifyAPI("PublicApi")

export default function SignUp() {
  const {encoded_signup_params} = useParams()
  let inititialEmail = ""
  let inititialInviteCode = ""

  if (encoded_signup_params) {
    const decoded = Buffer.from(encoded_signup_params, 'base64').toString('ascii')
    const tokens = decoded.split("&")

    if (tokens.length === 2) {
      for (let i = 0; i < tokens.length; i++) {
        const [key, val] = tokens[i].split("=")
        if (key === "email") {
          inititialEmail = val.trim()
        } else if (key === "invite") {
          inititialInviteCode = val.trim()
        }
      }
    } else {
      console.log("invalid signup params", encoded_signup_params)
    }
  }

  const validateInviteCode = ValidateStrFunc("1234567890", 6, 6)
  function handleInviteCodeChanged(valid, val) {
    console.log("updating valid invite", valid)
    setValidInvite(valid)
    setInviteCode(val)

    if (validEmail && valid) {
      checkInviteCode(email, val)
    }
  }

  const [inviteFound, setInviteFound] = useState(false)
  const [inviteErrMsg, setInviteErrMsg] = useState("")
  const [signupFailMsg, setSignupFailMsg] = useState("")
 
  const [email, setEmail] = useState(inititialEmail)
  const [inviteCode, setInviteCode] = useState(inititialInviteCode)
  const [validEmail, setValidEmail] = useState(ValidateEmail(inititialEmail)[0])
  const [validInvite, setValidInvite] = useState(validateInviteCode(inititialInviteCode)[0])

  //console.log("email:", email, "initial:", inititialEmail)
  //console.log("invite:", inviteCode, "initial:", inititialInviteCode)

  const [validFirstName, setValidFirstName] = useState(false)
  const [validLastName, setValidLastName] = useState(false)
  const [validPass, setValidPass] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPass, setConfirmPass] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  const navigate = useNavigate()

  function checkInviteCode(emailAddr, ic) {
    publicApi.get('/invites', {email: emailAddr, invite_code: ic}, results => {
      console.log('/invites returned ', results)
      setInviteFound(results.ok)

      if (!results.ok) {
        setInviteErrMsg("Invite Code unknown for this email")
      } else {
        setInviteErrMsg("")
      }
    }, error => {
      console.error(error)
      setInviteErrMsg(error.message)
    })
  }

  useEffect(() => {
    setCanSubmit(fieldsValid())

    if (validEmail && validInvite) {
      checkInviteCode(email, inviteCode)
    }
  }, [phoneNumber, validEmail, validInvite, validFirstName, validLastName, validPass, phoneNumber, email, inviteCode])

  function handleEmailChanged(valid, val) {
    console.log("email changed", valid, val)
    setValidEmail(valid)
    setEmail(val)

    if (validInvite && valid) {
      checkInviteCode(val, inviteCode)
    }
  }


  const validateFirstName = ValidateStrFunc(null, 1, 32)
  function handleFirstNameChanged(valid, val) {
    setFirstName(val)
    setValidFirstName(valid)
  }

  const validateLastName = ValidateStrFunc(null, 2, 64)
  function handleLastNameChanged(valid, val) {
    setLastName(val)
    setValidLastName(valid)
  }
  
  const validatePassword = ValidateStrFunc(null, 8, 32)
  function validatePassword1(val) {
    const [valid, errMsg] = validatePassword(val)
    if (!valid) {
      return [valid, errMsg]
    }

    if (val.length === 0 || confirmPass.length === 0) {
      return [false, ""]
    } else if (val !== confirmPass) {
      return [false, "Password and Confirm Password fields do not match"]
    } else {
      return [true, ""]
    }
  }

  function validatePassword2(val) {
    const [valid, errMsg] = validatePassword(val)
    if (!valid) {
      return [valid, errMsg]
    }

    if (password.length === 0 || val.length === 0) {
      return [false, ""]
    } else if (password !== val) {
      return [false, "Password and Confirm Password fields do not match"]
    } else {
      return [true, ""]
    }
  }

  function handlePasswordChanged(valid, val) {
    setValidPass(valid)
    setPassword(val)
  }

  function handleConfirmPassChanged(valid, val) {
    setValidPass(valid)
    setConfirmPass(val)
  }

  function extractSignupFailure(errMsg) {
    const msg = "with error "
    const pos = errMsg.indexOf(msg)

    if (pos >= 0) {
      const errMsgPos = pos + msg.length
      return errMsg.charAt(errMsgPos).toUpperCase() + errMsg.slice(errMsgPos + 1)
    }
  
    return errMsg
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (fieldsValid()) {
      setCanSubmit(false)
      console.log(firstName, lastName, email, inviteCode)

      const req = {
        username: email,
        password: password,
        attributes: {
        },
        autoSignIn: { // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
        clientMetadata: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          invite_code: inviteCode,
          phone_number: phoneNumber
        }
      }

      Auth.signUp(req).then(user => {
        console.log(user)

        new Promise(resolve => setTimeout(resolve, 1000)).then(() => {
          API.get("AdminApi", "/players").then(results => {
            console.log("admin/players", results)
            navigate("/admin")
          }).catch(error => {
            console.log("admin/players", error)
            navigate("/user")
          })
        })
      }).catch(error => {
        let errMsg = extractSignupFailure(error.message)
        setSignupFailMsg(errMsg)
        setCanSubmit(true)
      })
    }
  }

  function fieldsValid() {
    const valid = inviteFound && validFirstName && validLastName && validPass && phoneNumber !== ''
    console.log("inviteFound", inviteFound, "validFirstName", validFirstName, "validLastName", validLastName, "validPass", validPass, "phone_number", phoneNumber, "valid", valid)
    return valid
  }

  return (
    <div className="signin-root-div">
      <div className="signin-container">
        <div className="signin-img-div">
          <img className="signin-img-div" src="/breakers_logo.png" alt="Palisades Lacrosse" />
        </div>
        <form onSubmit={event => handleSubmit(event)}>
          <div className="signin-row">
            <LabeledInput name="email" label="Email:" type="email" maxLength={128} disabled={inviteFound} validate={ValidateEmail} handleChange={handleEmailChanged} delay={500} initialValue={email}/>
          </div>
          <div className="signin-row">
            <LabeledInput name="invite_code" label="Invite Code:" type="number" maxLength={6} disabled={inviteFound} validate={validateInviteCode} handleChange={handleInviteCodeChanged} delay={500} initialValue={inviteCode}/>
          </div>
          <div className="signin-row">
            <ErrorMessage text={inviteErrMsg} />
            <LabeledInput name="first_name" label="First Name:" type="text" maxLength={32} disabled={!inviteFound} validate={validateFirstName} handleChange={handleFirstNameChanged} delay={50} />
          </div>
          <div className="signin-row">
            <LabeledInput name="last_name" label="Last Name:" type="text" maxLength={64} disabled={!inviteFound} validate={validateLastName} handleChange={handleLastNameChanged} delay={50} />
          </div>
          <div className="signin-row">
            <LabeledInput name="password" label="Password:" type="password" maxLength={32} disabled={!inviteFound} validate={validatePassword1} handleChange={handlePasswordChanged} delay={50}/>
          </div>
          <div className="signin-row">
            <LabeledInput name="confirm_pass" label="Confirm Password:" type="password" maxLength={32} disabled={!inviteFound} validate={validatePassword2} handleChange={handleConfirmPassChanged} delay={50}/>
          </div>
          <div className="signin-row">
            <PhoneNumberInput name="phone_number" label="US Phone Number" disabled={!inviteFound} setPhoneNumber={setPhoneNumber} />
          </div>
          <div className="signin-row div-right">
              <button className="btn" disabled={!canSubmit}>Submit</button>
          </div>
          <ErrorMessage text={signupFailMsg} />
        </form>
        <div className="signin-row">
          <div>Already have an account? <Link to="/signin">Sign In</Link></div>
        </div>
      </div>
    </div>
  )
}
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify';
import { RiseLoader } from 'react-spinners';

export default function ProtectedRoute() {
  const navigate = useNavigate();
  const [routeValidated, setRouteValidated] = useState(false)
  
  useEffect(() => {
    Auth.currentUserPoolUser().catch(error => {
      console.log("not logged in")
      navigate("/signin")
    }).finally(() => {
      setRouteValidated(true)
    })
  }, [navigate])

  if (!routeValidated) {
    return (
      <div className='div-center'>
        <RiseLoader loading={true} size="25px" color="#36a7d7"/>
      </div>
    )
  } else {
    return (
      <Outlet />
    )
  }
}

import { Field, FormFields } from "../../utils/formfields"
import { getAlphaNumericWithSymbolsAndSpacesValidation, getNumberValidation, getStrValidation, getUrlValidation, getWordValidation, validate24HourTime, validateUuid, zipValidation } from "../../utils/validation"

export const locationFormFields = new FormFields()
locationFormFields.add(new Field("id", 0, null).withType("hidden"))
locationFormFields.add(new Field("name", 64, getStrValidation(4, 64)).withRequired())
locationFormFields.add(new Field("city", 64, getStrValidation(2, 64)).withRequired())
locationFormFields.add(new Field("state", 2, getWordValidation(2, 2)).withRequired())
locationFormFields.add(new Field("zip", 5, zipValidation).withRequired())
locationFormFields.add(new Field("address", 256, getStrValidation(4, 256)).withRequired())
locationFormFields.add(new Field("url", 128, getUrlValidation(true, 128)))

export const seasonFormFields = new FormFields()
seasonFormFields.add(new Field("id", 0, null).withType("hidden"))
seasonFormFields.add(new Field("name", 64, getAlphaNumericWithSymbolsAndSpacesValidation(4, 64)).withRequired())
seasonFormFields.add(new Field("start", 10, null).withDisplayName("Start Date").withType("date").withRequired())
seasonFormFields.add(new Field("end", 10, null).withDisplayName("End Date").withType("date").withRequired())

export const teamFormFields = new FormFields()
teamFormFields.add(new Field("id", 0, null).withType("hidden"))
teamFormFields.add(new Field("name", 64, getAlphaNumericWithSymbolsAndSpacesValidation(4, 64)).withRequired())
teamFormFields.add(new Field("season_id", 36, validateUuid).withType("hidden").withRequired())

export function getEventFormFields(locations, team_name) {
  const locationOptions = [{id: "", option: "TBD"}]
  for (let i = 0; i < locations.length; i++) {
    locationOptions.push({id: locations[i].id, option: locations[i].name})
  }

  const eventFormFields = new FormFields()
  eventFormFields.add(new Field("id", 0, validateUuid).withType("hidden"))
  eventFormFields.add(new Field("season_id", 36, validateUuid).withType("hidden").withRequired())
  eventFormFields.add(new Field("team_id", 36, validateUuid).withType("hidden").withEmptyAsNull())
  eventFormFields.add(new Field("title", 64, getStrValidation(4, 64)).withRequired())
  eventFormFields.add(new Field("team", 256, null).withType("const").withConstValue(team_name).withEmptyAsNull())
  eventFormFields.add(new Field("start_date", 10, null).withDisplayName("Date").withType("date").withRequired())
  eventFormFields.add(new Field("start_time", 10, validate24HourTime).withDisplayName("Time 24h (Blank for TBD)"))
  eventFormFields.add(new Field("length_mins", 3, null).withType("number").withDisplayName("Length in Minutes").withRequired())
  eventFormFields.add(new Field("location_id", 36, validateUuid).withDisplayName("Location").withType("select").withEmptyAsNull().withOptions(locationOptions))

  return eventFormFields
}


export const playerFormFields = new FormFields()
playerFormFields.add(new Field("id", 0, null).withType("hidden"))
playerFormFields.add(new Field("first", 64, getStrValidation(2, 64)).withRequired())
playerFormFields.add(new Field("last", 64, getStrValidation(2, 64)).withRequired())
playerFormFields.add(new Field("middle", 2, getStrValidation(0, 64)).withEmptyAsNull())
playerFormFields.add(new Field("grad_year", 4, getNumberValidation(4, 4)).withType("number").withRequired())
playerFormFields.add(new Field("position", -1, null).withType("select").withOptions([{id:"goalie", option:"Goalie"},{id:"nongoalie", option:"NonGoalie"}, {id:"any", option:"Any"}]).withRequired())
import React from 'react'
import { FormatPhoneNumber, TitleCase } from '../../utils/formatting';

export default function GuardianInfo({info}) {

  return (
    <>
      <div className='admin-row'>Name:</div>
      <div className='div-right'>{TitleCase([info.first_name, info.last_name].join(' '))}</div>
      <div className='admin-row'>Email:</div>
      <div className='div-right'>{info.email}</div>
      <div className='admin-row'>Phone:</div>
      <div className='div-right'>{FormatPhoneNumber(info.phone_number)}</div>
    </>
  )
}

export class Feed {
  constructor(orderedDates, dateToEvents, pidToPlayer) {
    this.orderedDates = orderedDates
    this.dateToEvents = dateToEvents
    this.pidToPlayer = pidToPlayer
  }

  static build(feedEvents, feedPlayers, locations) {
    const dates = new Set()
    const dateToEvents = new Map()
    const pidToPlayer = new Map()

    if (feedEvents != null) {
    for (let i = 0; i < feedEvents.length; i++) {
      const feedEvent = feedEvents[i]
      const eventDate = new Date(Date.parse(feedEvent["start"]))
      const noTime = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate()).getTime()
      dates.add(noTime)

      feedEvent["location"] = locations.get(feedEvent["location_id"])

      if (dateToEvents.has(noTime)) {
        dateToEvents.get(noTime).push(feedEvent)
      } else {
        dateToEvents.set(noTime, [feedEvent])
      }
    }
  }

    if (feedPlayers != null) {
      for (let i = 0; i < feedPlayers.length; i++) {
        pidToPlayer.set(feedPlayers[i]["id"], feedPlayers[i])
      }
    }

    const orderedDates = Array.from(dates)
    orderedDates.sort((a, b) => {
      if (a < b) {
        return -1
      } else {
        return 1
      }
    })

    return new Feed(orderedDates, dateToEvents, pidToPlayer)
  }

  getEventsAfter(date) {
    const events = []
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
    for (let i = 0; i < this.orderedDates.length; i++) {
      if (this.orderedDates[i] >= startOfDay) {
        const dateEvents = this.dateToEvents.get(this.orderedDates[i])
        events.push(dateEvents)
      }
    }

    return events
  }

  groupEventsByPlayer(events) {
    const pidToEvents = new Map()
    for (let i=0; i < events.length; i++) {
        const pid = events[i]["person_id"]
        if (pidToEvents.has(pid)) {
          pidToEvents.get(pid).push(events[i])
        } else {
          pidToEvents.set(pid, [events[i]])
        }
    }

    return pidToEvents
  }

  getPlayer(pid) {
    return this.pidToPlayer.get(pid)
  }
}
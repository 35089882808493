
const localPartValidChars = new Set("abcdefghijklmnopqrstuvwxyz0123456789!#$%&'*+-/=?^_`{|}~.")
const labelValidChars = new Set("abcdefghijklmnopqrstuvwxyz0123456789-")

export function ValidateEmail(emailAddr) {
  function validateLocalPart(str) {
    if (str.length === 0) {
      return [false, ""]
    } else if(str.length > 64) {
      return [false, str + " exeeds 64 characters"]
    }

    for (let i = 0; i < str.length; i++) {
      if (!localPartValidChars.has(str.charAt(i))) {
        return [false, "Invalid character '" + str.charAt(i) + "' found in " + str]
      }
    }

    if (str.indexOf("..") !== -1) {
        return [false, "Invalid sequence '..'"]
    } else if (str.charAt(0) === '.') {
        return [false, str + " cannot begin with a '.'"]
    } else if (str.charAt(str.length-1) === '.') {
        return [false, str + " cannot end with a '.'"]
    }
      
    return [true, ""]
  }
      
  function validateLabel(str) {
    if (str.length === 0) {
        return [false, "Periods may not begin, end, or be consecutive"]
    }
      
    for (let i = 0; i < str.length; i++) {
      if (!labelValidChars.has(str.charAt(i))) {
        return [false, "Invalid character '" + str.charAt(i) + "' found in " + str]
      }
    }
      
    return [true, ""]
  }
  
  function validateDomain(str) {
    if (str.length === 0) {
      return [false, "Missing domain"]
    }
      
    const parts = str.split('.')
    if (parts.length < 2) {
      return [false, str + " is not a valid domain"]
    }
    
    for (let i = 0; i < parts.length; i++) {
        const [isValid, reason] = validateLabel(parts[i])
        if (!isValid) {
            return [false, reason]
        }
    }
      
    return [true, reason]
  }

  if (emailAddr.length === 0) {
      return [false, ""]
  } if (emailAddr.length > 128) {
      return [false, "Email address is too long"]
  }

  const parts = emailAddr.split('@')
  
  if (parts.length < 2) {
    return [false, "Missing @domain portion of email"]
  } else if (parts.length > 2) {
    return [false, "More than one '@a' character"]
  }

  let [isValid, reason] = validateLocalPart(parts[0])
  if (!isValid) {
      return [false, reason]
  }
      
  return validateDomain(parts[1])
}

export function ValidateStrFunc(validCharStr, minLength, maxLength) {
  let validChars = null
  if (validCharStr != null && validCharStr.length > 0) {
    validChars = new Set(validCharStr)
  }

  return ValidateStrFuncWithCharSet(validChars, minLength, maxLength)
}

export function ValidateStrFuncWithCharSet(validChars, minLength, maxLength) {
  return str => {
    if(str.length === 0 && minLength > 0) {
      return [minLength <= 0, ""]
    } else if (minLength === maxLength) {
      if (str.length !== minLength) {
        return [false, "Field must be " + minLength + " characters long"]
      }      
    } else {
      if (str.length < minLength) {
        return [false, "Field must be at least " + minLength + " characters long"]
      } else if (str.length > maxLength) {
        return [false, "Field must be shorter than " + maxLength + " characters long"]
      }
    }

    if (validChars !== null) {
      for (let i = 0; i < str.length; i++) {
        if (!validChars.has(str.charAt(i))) {
          return [false, "Invalid character '" + str.charAt(i) + "' found in " + str]
        }
      }
    }

    return [true, ""]
  }
}

const alphaNumeric = new Set("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ")
const alphaNumericWithSpaces = new Set("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ")
const alphaNumericWithSymbols = new Set("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~!@#$%^&*()-_=+[]{}\\|;:'\",<.>/?")
const alphaNumericWithSymbolsAndSpaces = new Set("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ `~!@#$%^&*()-_=+[]{}\\|;:'\",<.>/?")
const numbers = new Set("0123456789")
const validHex = new Set("0123456789abcdefABCDEF")


export function getAlphaNumericValidation(minLen, maxLen) {
  return ValidateStrFuncWithCharSet(alphaNumeric, minLen, maxLen)
}

export function getAlphaNumericWithSymbolsValidation(minLen, maxLen) {
  return ValidateStrFuncWithCharSet(alphaNumericWithSymbols, minLen, maxLen)
}

export function getAlphaNumericWithSymbolsAndSpacesValidation(minLen, maxLen) {
  return ValidateStrFuncWithCharSet(alphaNumericWithSymbolsAndSpaces, minLen, maxLen)
}

export function getStrValidation(minLen, maxLen) {
  return ValidateStrFuncWithCharSet(alphaNumericWithSpaces, minLen, maxLen)
}

export function getWordValidation(minLen, maxLen) {
  return ValidateStrFuncWithCharSet(alphaNumeric, minLen, maxLen)
}

export function getNumberValidation(minLen, maxLen) {
  return ValidateStrFuncWithCharSet(numbers, minLen, maxLen)
}

export const zipValidation = ValidateStrFuncWithCharSet(numbers, 5, 5)

const urlCharacters = new Set("ABCDEFGHIJKLMNOPQRSTUVWXYZZabcdefghijklmnopqrstuvwxyzz0123456789-._~:/?#[]@!$&'()*+,;%=")
export function getUrlValidation(nullable, maxLen) {
  const minLen = 10
  const charValidFunc = ValidateStrFuncWithCharSet(urlCharacters, minLen, maxLen)
  return str => {
    if (str.length === 0 && nullable) {
      return [true, ""]
    }

    let [valid, errMsg] = charValidFunc(str)
    if (!valid) {
      return [valid, errMsg]
    }

    const isHttps = str.startsWith("https://")
    if (!isHttps) {
      const isHttp = str.startsWith("http://")
      if (!isHttp) {
        return [false, "urls must start with http:// or https://"]
      }
    }

    return [true, ""]
  }
}

export function validateUuid(str) {
  if (str.length === 0) {
    return [false, "Missing uuid"]
  } else if (str.length !== 36) {
    return [false, "Invalid uuid length"]
  }

  const parts = str.split('-')
  if (parts.length !== 5) {
    return [false, "Invalid uuid format"]
  }

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i]
    if (part.length !== 8 && i === 0) {
      return [false, "Invalid uuid format"]
    } else if (part.length !== 12 && i === 4) {
      return [false, "Invalid uuid format"]
    } else if (part.length !== 4 ) {
      return [false, "Invalid uuid format"]
    }

    for (let j = 0; j < part.length; j++) {
      if (!validHex.has(part.charAt(j))) {
        return [false, "Invalid uuid format"]
      }
    }
  }

  return [true, ""] 
}

const timeValidateFunc = ValidateStrFuncWithCharSet(new Set("0123456789:"), 0, 10)
export function validate24HourTime(str) {
  if (str.length === 0) {
    return [true, ""]
  }
  
  const [valid, errMsg] = timeValidateFunc(str)
  if (!valid) {
    return [valid, errMsg]
  }

  const parts = str.split(':')
  if (parts.length !== 2) {
    return [false, "Invalid time format"]
  }

  const hour = parseInt(parts[0])
  if (hour < 0 || hour > 23) {
    return [false, "Invalid hour"]
  }

  if (parts[1].length !== 2) {
    return [false, "Invalid minute"]
  } else {
    const minute = parseInt(parts[1])
    if (minute < 0 || minute > 59) {
      return [false, "Invalid minute"]
    }
  }

  return [true, ""]
}
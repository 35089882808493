import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AmplifyAPI } from '../../utils/api'
import DynamicForm from '../components/DynamicForm'
import { seasonFormFields } from './FormDefs'
import { getHandleSeasonSubmit } from './SeasonEdit'

export default function SeasonCreate() {
  const adminApi = new AmplifyAPI("AdminApi")
  const navigate = useNavigate()
  const handleSeasonSubmit = getHandleSeasonSubmit(adminApi, navigate)

  return (
    <section className='admin-content-section'>
      <div className='admin-container-narrow'>
        <h3 className='div-center'>Season</h3>
        <DynamicForm fields={seasonFormFields} handleSubmit={handleSeasonSubmit}/>
      </div>
    </section>
  )
}

import { API, Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { RiseLoader } from 'react-spinners';

export default function PublicRoute() {
    const navigate = useNavigate();
    const [routeValidated, setRouteValidated] = useState(false)

    useEffect(() => {
      Auth.currentUserPoolUser().then(() => {
        API.get("AdminApi", "/players").then(() => {
          navigate("/admin")
        }).catch(error => {
          navigate("/user")
        })
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        setRouteValidated(true)
      })
    }, [navigate])

  if (!routeValidated) {
    return (
      <div className='div-center'>
        <RiseLoader loading={true} size="25px" color="#36a7d7"/>
      </div>
    )
  } else {
    return (
      <Outlet />
    )
  }
}

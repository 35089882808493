const Features = new Set();

export const PublishedRosters = "published_rosters"

function addFeature(feature) {
  console.log(`Adding feature: ${feature}`);
  Features.add(feature);
}

export function initFeatures() {
  if (window.location.hostname === 'localhost') {
  }

  addFeature(PublishedRosters);
}

export function featureEnabled(feature) {
  return Features.has(feature);
}
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI, formFieldsToObject } from '../../utils/api'
import { dateAndTimeStringsToDate, dateStrToDateAndTimeStrings } from '../../utils/date'
import DynamicForm from '../components/DynamicForm'
import { getEventFormFields } from './FormDefs'

export function getHandleEventSubmit(adminApi, navigate, formFields, season_id, event_id) {
  return function handleEventSubmit(event) {
    event.preventDefault()

    let date = null
    let time = null
    for (let i = 0; i < event.target.length; i++) {
      const field = event.target[i]
      if (field.id === "start_date") {
        date = field.value
      } else if (field.id === "start_time") {
        time = field.value
      }
    }

    let start = dateAndTimeStringsToDate(date, time)

    const data = formFieldsToObject(formFields, event)
    data.start = start.toISOString()
    adminApi.post("/events", {}, data, results => {
      console.log("POST /events results", results)
      navigate('/admin/season/' + season_id + '/event/' + event_id)
    }, error => console.error(error))
  }
}

const adminApi = new AmplifyAPI("AdminApi")

export default function EventEdit() {
  const navigate = useNavigate()
  const {season_id, event_id} = useParams()

  const [event, setEvent] = useState(null)
  useEffect(() => {
    // get event
    adminApi.get("/events", {"event_id":event_id}, results => {
      console.log("GET event", results)
      const [testDate, testTime] = dateStrToDateAndTimeStrings(results.event.start)
      let newEvent = {
        ...results.event,
        start_date: new Date(Date.parse(testDate)),
        start_time: testTime,
      }

      setEvent(newEvent)
        
    }, error => console.error(error))
  },[event_id])

  const [locations, setLocations] = useState(null)
  useEffect(() => {
    // get locations
    adminApi.get("/locations", null, results => {
      if (results == null) {
        results = []
      }
      setLocations(results)
    }, error => console.error(error))
  }, [])

  const [team, setTeam] = useState(null)
  useEffect(() => {
    if (event == null) {
      return
    }

    if (event.team_id == null) {
      setTeam({name: "All Players"})
      return
    }

    // get team
    adminApi.get("/seasons/teams", {"team_id":event.team_id}, results => {
      setTeam(results)
    }, error => console.error(error))
  }, [event])
      
  if (event == null || locations == null || team == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    const eventFormFields = getEventFormFields(locations, team.name)
    const handleSubmit = getHandleEventSubmit(adminApi, navigate, eventFormFields, season_id, event_id)
    return (
      <section className='admin-content-section'>
        <div className='admin-container'>
          <DynamicForm  fields={eventFormFields} initialValue={event} handleSubmit={handleSubmit} />
        </div>
      </section>
    )
  }
}

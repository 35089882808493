import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { AmplifyAPI, formFieldsToObject } from '../../utils/api'
import DynamicForm from '../components/DynamicForm'
import { playerFormFields } from './FormDefs'

const adminApi = new AmplifyAPI("AdminApi")

export default function PlayerEdit() {
  const {id} = useParams()
  const navigate = useNavigate()
  const [player, setPlayer] = useState(null)

  useEffect(() => {
    const path = "/players"
    const params = {"player_id":id}
    adminApi.get(path, params, results => {
      results.grad_year = results.grad_year.toString()
      setPlayer(results)
    }, error => console.error(error))
  }, [id])

  function submitPlayer(event) {
    event.preventDefault()
    const path = "/players"
    const data = formFieldsToObject(playerFormFields, event)

    adminApi.post(path, {}, data, results => {
      console.log(results)
      navigate('')
    }, error => console.error(error))
  }


  if (player == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    return (
      <section className='admin-content-section'>
        <div className='admin-container'>
          <DynamicForm  fields={playerFormFields} initialValue={player} handleSubmit={submitPlayer}/>
        </div>
      </section>
    )
  }
}

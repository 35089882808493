import React, { useState } from 'react'

// CalcInitialMapping takes the headers from the csv file and lists of required and optional headers
// as well as a map of how the headers should be mapped to the form fields and returns a map of
// the actual mapping of form fields to headers based on the headers from the csv file. It will
// have null values for any headers that are not in the csv file.
export function CalcMapping(headers, required, optional, expected) {
  if (headers === null) {
    return new Map()
  }

  const actual = new Map()

  // loop through required and optional adding the values to actual with a value of null
  for (let i = 0; i < required.length; i++) {
    actual.set(required[i], null)
  }

  for (let i = 0; i < optional.length; i++) {
    actual.set(optional[i], null)
  }

  // loop through headers and if the header is in expected, set the value in actual
  for (let i = 0; i < headers.length; i++) {
    const header = headers[i]
    if (expected.has(header)) {
      actual.set(expected.get(header), header)
    }
  }

  return actual
}

export default function CsvHeaderMapper({headers, required, optional, mapping, onMappingChanged}) {
  function handleSelectBoxChanged(event) {
    const field = event.target.name
    const value = event.target.value

    const newMapping = new Map(mapping)
    newMapping.set(field, value)

    onMappingChanged(newMapping)
  }

  const requiredAndOptional = [...required, ...optional]

  if (headers === null) {
    return null
  } else {
    return (

      <section className='admin-content-section'>
        <div className='admin-container'>
          <div>
            {requiredAndOptional.map(field => {
              return (
                <div className='admin-row' key={field}>
                  <label>{field}</label>
                  <select className='inpt' name={field} value={mapping.get(field)} onChange={handleSelectBoxChanged}>
                    <option value={null}></option>
                    {headers.map(header => {
                      return (
                        <option key={header} value={header}>{header}</option>
                      )
                    })}
                  </select>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

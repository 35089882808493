import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ValidateEmail, ValidateStrFunc } from '../utils/validation'
import ErrorMessage from './components/ErrorMessage'
import LabeledInput from './components/LabeledInput'
import { Auth } from 'aws-amplify';
import ButtonOrBusy from './components/ButtonOrBusy'

export default function PasswordReset() {
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const [validEmail, setValidEmail] = useState(false)
  const [email, setEmail] = useState("")
  const [code, setCode] = useState("")
  const [validCode, setValidCode] = useState(false)
  const [password, setPassword] = useState("")
  const [validPass, setValidPass] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [busy, setBusy] = useState(false)
  const navigate = useNavigate();
  
  function canSubmit() {
    console.log(validEmail, validPass, validCode)
    if (!emailSubmitted) {
      return validEmail
    } else {
      return validCode && validPass
    }
  }

  function handleEmailChanged(valid, val) {
      setValidEmail(valid)
      setEmail(val)
  }
  
  function handleSubmit(event) {
    event.preventDefault();

    setBusy(true)
    setErrorMsg("")
    if (!emailSubmitted) {
      Auth.forgotPassword(email).then(() => {
        setEmailSubmitted(true)
      }).catch(error => {
        if (error.name === "UserNotFoundException") {
          setErrorMsg(email + " is not a known email address.")
        } else {
          setErrorMsg(error.message)
        }
      }).finally(() => setBusy(false))
    } else {
      Auth.forgotPasswordSubmit(email, code, password).then(() => {
        navigate("/signin")
      }).catch(error => {
        setErrorMsg(error.message)
        setBusy(false)
      })
    }
  }

  const validatePass = ValidateStrFunc(null, 8, 32)
  function handlePassChange(valid, val) {
    setValidPass(valid)
    setPassword(val)
  }

  function validateCode(val) {
    if (val.length === 0) {
      return [false, ""]
    } else if (val.length === 6 && /^\d+$/.test(val)) { 
      return [true, ""]
    } else {
      return [false, "Reset codes are 6 digit numbers"]
    }
  }

  function handleCodeChange(valid, val) {
    setValidCode(valid)
    setCode(val)
  }

  function renderCodeAndPass() {
    if (emailSubmitted) {
      return (
        <>
          <div className="signin-row">An email has been sent to {email} with the code necessary to reset your password.</div>
          <div className="signin-row">
              <LabeledInput name="code" label="Code:" type="text" maxLength={6} validate={validateCode} handleChange={handleCodeChange} handleChadelay={500}/>
          </div>
          <div className="signin-row">
              <LabeledInput name="password" label="New Password:" type="password" maxLength={32} validate={validatePass} handleChange={handlePassChange} delay={500}/>
          </div>
        </>
      )
    }
  }
  
  return (    
    <div className="signin-root-div">
        <div className="signin-container">
            <div className="signin-img-div">
                <img className="signin-img-div" src="/breakers_logo.png" alt="Palisades Lacrosse" />
            </div>
            <form onSubmit={event => handleSubmit(event)}>
                <div className="signin-row">
                    <LabeledInput name="email" label="Email:" type="email" maxLength={128} disabled={emailSubmitted} validate={ValidateEmail} handleChange={handleEmailChanged} delay={500}/>
                </div>
                {renderCodeAndPass()}
                <div className="signin-row div-right">
                    <ButtonOrBusy className="btn" disabled={!canSubmit()} busy={busy} text="Submit" />
                </div>
                <ErrorMessage text={errorMsg} />
            </form>
            <div className="signin-row">
                <Link to="/signup">Sign Up</Link> for an Account
            </div>
        </div>
    </div>
)
}

import React, { useState } from 'react'
import DynamicTable from '../components/DynamicTable'
import { TitleCase, AgeForDOB } from '../../utils/formatting'
import EventPlayersSave from './EventPlayersSave'

function processRemove(roster, setRoster, event, id) {
  event.stopPropagation()
  let newRoster = roster.removePlayerFromRoster(id)
  setRoster(newRoster)
}

function getRemoveButton(roster, setRoster, id) {
  return(
    <button className='btn btn-cancel' onClick={event => processRemove(roster, setRoster, event, id)}>x</button>
  )
}

function processAdd(roster, setRoster, event, id) {
  event.stopPropagation()
  let newRoster = roster.addPlayerToRoster(id)
  setRoster(newRoster)
}

function getAddButton(roster, setRoster, id) {
  return(
    <button className='btn btn-add' onClick={event => processAdd(roster, setRoster, event, id)}>+</button>
  )
}

function availabilityForRSVP(rsvp) {
  if (!rsvp || rsvp === "none") {
    return "?"
  } else if (rsvp === "yes") {
    return "Y"
  } else {
    return "N"
  }
}

const availabilityStrToVal = new Map([['Y', 0], ['?', 1], ['N',2]])

function sortPlayers(players) {
  players.sort((p1,p2) => {
    const a1 = availabilityStrToVal.get(p1.cols[2])
    const a2 = availabilityStrToVal.get(p2.cols[2])
    if (a1 < a2) {
      return -1
    } else if (a1 > a2) {
      return 1
    } else {
      if (p1.cols[1] < p2.cols[1]) {
        return -1
      } else {
        return 1
      }
    }
  })
}
  
export default function EventPlayers({isRostered, initialRoster, availability, onRosterSave}) {
  const [roster, setRoster] = useState(initialRoster)

  if (roster == null) {
    return null
  }

  const diffCh = new Map([[true, '*'], [false, '']])
  let numDiffs = 0

  const nonrosteredPlayers = []
  roster.forEachNonrostered(person => {
    const differs = initialRoster.isOnTheRoster(person.id)
    const personAvailabilty = availability.get(person.id)
    numDiffs += differs ? 1 : 0
    const player = {
      url: "../player/" + person.id,
      cols: [
        diffCh.get(differs),
        TitleCase([person.first,person.last].join(' ')), 
        availabilityForRSVP(personAvailabilty),
        AgeForDOB(person.dob), 
        person.grad_year,
        person.position !== "nongoalie" ? "Yes" : "No"
      ]
    }

    console.log(person.first + " " + person.last + " " + personAvailabilty)

    if (isRostered) {
      player.cols.push(getAddButton(roster, setRoster, person.id))
    }

    nonrosteredPlayers.push(player)
  })
  
  sortPlayers(nonrosteredPlayers)
  
  const rosterHeaders = ["", "Name", "Available", "Age", "Grad Year", "Plays Goalie"]

  if (!isRostered) {
    return (
      <>
        <h3>Players</h3>
        <DynamicTable headers={rosterHeaders} rows={nonrosteredPlayers}/>
      </>
    )
  } else {
    rosterHeaders.push("")
    const rosteredPlayers = []
    console.log(roster)
    console.log(initialRoster)
    roster.forEachRostered(person => {
      console.log(person)
      const differs = !initialRoster.isOnTheRoster(person.id)
      const personAvailabilty = availability.get(person.id)
      numDiffs += differs ? 1 : 0
      rosteredPlayers.push({
        url: "../player/" + person.id,
        cols: [
          diffCh.get(differs),
          TitleCase([person.first,person.last].join(' ')), 
          availabilityForRSVP(personAvailabilty),
          AgeForDOB(person.dob), 
          person.grad_year,
          person.position !== "nongoalie" ? "Yes" : "No",
          getRemoveButton(roster, setRoster, person.id),
        ]
      })
    })

    sortPlayers(rosteredPlayers)

    return (
      <>
        <h3>Rostered Players</h3>
        <DynamicTable headers={rosterHeaders} rows={rosteredPlayers}/>
        <h3>Nonrostered Players</h3>
        <DynamicTable headers={rosterHeaders} rows={nonrosteredPlayers}/>
        <br/><br/>
        <EventPlayersSave numDiffs={numDiffs} roster={roster} onRosterSave={onRosterSave} />
      </>
    )
  }
}

export class CsvReader {
  constructor(file) {
    this.file = file
    this.headers = null
    this.rows = []
    this.error = null
  }
  
  Headers() {
    return this.headers
  }

  HeadersLower() {
    const headers = []
    for (let i = 0; i < this.headers.length; i++) {
      headers.push(this.headers[i].toLowerCase())
    }
    return headers
  }

  Rows() {
    return this.rows
  }

  Error() {
    return this.error
  }
  
  Read(cb) {
    // read the file
    const reader = new FileReader()
    reader.onload = event => {
      const text = event.target.result
      this.parse(text)
      cb(this)
    }
    reader.onerror = event => {
      console.error("onerror", event)
      this.error = event
      cb(this)
    }
    reader.readAsText(this.file)
  }

  parse(text) {
    // split the text into lines
    const lines = text.split("\n")

    // call parse line on each line
    for (let i = 0; i < lines.length; i++) {
      this.parseLine(i === 0, lines[i])
    } 

  }

  parseLine(isHeader, line) {
    // parse the line into fields handling quoted fields
    const fields = []
    let field = ""
    let inQuote = false
    for (let i = 0; i < line.length; i++) {
      const c = line[i]
      if (c === '"') {
        inQuote = !inQuote
      } else if (c === ',' && !inQuote) {
        fields.push(field)
        field = ""
      } else {
        field += c
      }
    }
    fields.push(field)
    
    if (isHeader) {
      this.headers = fields
    } else {
      this.rows.push(fields)
    }
  }
}
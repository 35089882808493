import { API } from "aws-amplify";

export function MapToQueryStrParams(params) {
  let encoded = ""

  if (params) {
    let pairs = []
    for (const [key, value] of Object.entries(params)) {
      const urlEncodedValue = encodeURIComponent(value)
      pairs.push(key + "=" + urlEncodedValue)
    }

    encoded = '?' + pairs.join('&')
  }

  return encoded
}

export function formFieldsToObject(formFieldDefs, form) {
  const fields = form.target
  const obj = {}
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i]
    const fieldDef = formFieldDefs.get(field.id)

    if (!fieldDef) {  
      continue
    }

    console.log("field", field)
    if (fieldDef.type === "input" || fieldDef.type === "hidden") {
      obj[field.id] = field.value
    } else if(fieldDef.type === "number") {
      obj[field.id] = parseInt(field.value)
    }else if (fieldDef.type === "date") {
      const d = new Date(Date.parse(field.value))
      obj[field.id] = d.toISOString()
    } else {
      obj[fieldDef.name] = field.value
    }
  }

  for (let i = 0; i < formFieldDefs.fields.length; i++) {
    const fieldDef = formFieldDefs.fields[i]
    if (fieldDef.emptyAsNull) {
      if (obj[fieldDef.name] === "") {
        obj[fieldDef.name] = null
      }
    }
  }

  console.log("formFieldsToObject", obj)
  return obj
}

export class AmplifyAPI {
  constructor(apiName) {
    this.apiName = apiName
  }
  
  get(path, params, cb, ecb, fcb) {
    const queryStrParams = MapToQueryStrParams(params)
    const fullPath = path + queryStrParams

    console.log("get", fullPath)
    API.get(this.apiName, fullPath)
      .then(result => {
        if (cb) {
          cb(result)
        }
      })
      .catch(error => {
        if (ecb) {
          ecb(error)
        }
      })
      .finally(() => {
        if (fcb) {
          fcb()
        }
      })
  }
  
  delete(path, params, cb, ecb, fcb) {
    const queryStrParams = MapToQueryStrParams(params)
    const fullPath = path + queryStrParams

    console.log("delete", fullPath)
    API.del(this.apiName, fullPath)
      .then(result => {
        if (cb) {
          cb(result)
        }
      })
      .catch(error => {
        if (ecb) {
          ecb(error)
        }
      })
      .finally(() => {
        if (fcb) {
          fcb()
        }
      })
  }

  post(path, params, body, cb, ecb, fcb) {
    const queryStrParams = MapToQueryStrParams(params)
    const fullPath = path + queryStrParams
    const reqData = {
      body: body,
    };

    console.log("post", fullPath, reqData)
    API.post(this.apiName, fullPath, reqData)
      .then(result => {
        if (cb) {
          cb(result)
        }
      })
      .catch(error => {
        if (ecb) {
          ecb(error)
        }
      })
      .finally(() => {
        if (fcb) {
          fcb()
        }
      })
  }

  put(path, params, body, cb, ecb, fcb) {
    const queryStrParams = MapToQueryStrParams(params)
    const fullPath = path + queryStrParams
    const reqData = {
      body: body,
    };

    API.put(this.apiName, fullPath, reqData)
      .then(result => {
        if (cb) {
          cb(result)
        }
      })
      .catch(error => {
        if (ecb) {
          ecb(error)
        }
      })
      .finally(() => {
        if (fcb) {
          fcb()
        }
      })
  }
}
import React from 'react'

export default function SeasonDropdown({seasons, seasonId, setSeasonId}) {
  const selectedValue = seasonId ? seasonId : ""
  return (
    <select className='slct' value={selectedValue} onChange={e => setSeasonId(e.target.value)}>
      <option value={""}>Select a season</option>
      {seasons && seasons.map(season => {
        return (
          <option key={season.id} value={season.id}>{season.name}</option>
        )
      })}
    </select>
  )
}

import React, { useEffect, useState } from 'react'
import { AmplifyAPI } from "../../utils/api"
import DeleteEntity from '../components/DeleteEntity'
import { useNavigate, useParams } from 'react-router-dom'
import { ParseEventDetails } from '../../utils/wire'
import { RiseLoader } from 'react-spinners'
import { dateToDateAndTimeDisplayStrings } from '../../utils/date'
import { renderLocation } from './Event'
import ErrorMessage from '../components/ErrorMessage'

const adminApi = new AmplifyAPI("AdminApi")

export default function EventDelete() {
  const {season_id, event_id} = useParams()
  const navigate = useNavigate()
  const [eventData, setEventData] = useState(null)

  useEffect(() => {
      adminApi.get("/events", {"event_id":event_id}, results => {
        console.log("Event results", results)
        setEventData(ParseEventDetails(results))
      }, error => console.error(error))
  },[event_id])

  const [errMsg, setErrMsg] = useState("")
  function handleDelete(success, msg) {
    console.log("handleDelete", success, msg)
    if (success) {
      navigate("/admin/season/" + season_id)
    } else {
      setErrMsg(msg)
    }
  }

  if (eventData == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    const [dateStr, timeStr] = dateToDateAndTimeDisplayStrings(eventData.start)
    return (      
      <section className='admin-content-section'>
        <div className='admin-container-narrow'> 
          <div>
            <span className='event-info-label-span'>Event:</span>
            <span className='event-info-value-span'>{eventData.title}</span>
          </div>
          <div>
            <span className='event-info-label-span'>Date:</span>
            <span className='event-info-value-span'>{dateStr}</span>
          </div>
          <div>
            <span className='event-info-label-span'>Time:</span>
            <span className='event-info-value-span'>{timeStr}</span>
          </div>
          <div>
            <span className='event-info-label-span'>Length:</span>
            <span className='event-info-value-span'>{eventData.length} minutes</span>
          </div>
          <div>
            <span className='event-info-label-span'>Location:</span>
            <span className='div-right'>
              {renderLocation(eventData.location)}
            </span>
          </div>
          <br/>
          <DeleteEntity api={adminApi} path="/seasons/events" args={{event_id:event_id}} onDelete={handleDelete}/>
          <ErrorMessage text={errMsg}/>
        </div>
      </section>
    )
  }
}


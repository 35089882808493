import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AmplifyAPI } from '../../utils/api'
import DynamicForm from '../components/DynamicForm'
import { locationFormFields } from './FormDefs'
import { getHandleLocationSubmit } from './LocationEdit'



export default function LocationCreate() {
  const adminApi = new AmplifyAPI("AdminApi")
  const navigate = useNavigate()
  const handleLocationSubmit = getHandleLocationSubmit(adminApi, navigate)

  return (
    <section className='admin-content-section'>
      <div className='admin-container-narrow'>
        <h3 className='div-center'>Location</h3>
        <DynamicForm fields={locationFormFields} handleSubmit={handleLocationSubmit}/>
      </div>
    </section>
  )
}

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { RiseLoader } from 'react-spinners'
import { dateToDateAndTimeDisplayStrings } from '../../utils/date'
import { featureEnabled, PublishedRosters } from '../../utils/features'
import { TitleCase } from '../../utils/formatting'
import ErrorMessage from './ErrorMessage'

function getSeasonNameFromSeasonId(player, seasonId) {
  const teams = player.team
  if (teams !== null) {
    for (let i = 0; i < teams.length; i++) {
      if (teams[i].seasonId === seasonId) {
        return teams[i].seasonName
      }
    }
  }

  return ""
}

function getTeamDiv(player, teamId) {
  const teams = player.team
  if (teams !== null) {
    for (let i = 0; i < teams.length; i++) {
      if (teams[i].teamId === teamId) {
        return (
          <>
            <div>Team:</div>
            <div className='div-right'>
              {teams[i].teamName}
            </div>
          </>
        )
      }
    }
  }

  return ""
}

export default function DateCardEventItemDetails({player, initialCalEvent, onRSVPChanged}) {
  const [calEvent, setCalEvent] = useState(initialCalEvent)

  function extractTime(dt) {
    const [, time] =  dateToDateAndTimeDisplayStrings(new Date(Date.parse(dt)))
    return time
  }

  function renderLocation(event) {
    if (event.location_id === null) {
      return (<div>TBD</div>)
    }
  
    const location = event.location
    const displayedLoc = (
      <>
        {location.name}<br/>
        {location.address}<br/>
        {location.city}, {location.zip}
      </>
    )
  
    if (location.url && location.url.length > 0) {
      return (
        <a href={location.url} target="_blank"  rel="noreferrer">
          {displayedLoc}
        </a>
      )
    } 
  
    return displayedLoc
  }

  const [errMsg, setErrMsg] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  function selectOrLoader(calEvent) {
    if (loading) {
      return (
        <RiseLoader loading={true} size="25px" color="#36a7d7"/>
      )
    } else {
      return (
        <select className='slct slct-large' defaultValue={calEvent.rsvp} onChange={event => {
          setLoading(true)
          onRSVPChanged(calEvent, event.target.value, rsvpChangeSuccess, rsvpChangeError)
        }}>
          <option value='none'>? </option>
          <option value='yes'>Yes</option>
          <option value='no'>No</option>
        </select>
      )
    }
  }

  function rsvpChangeSuccess(rsvp) {
    const newCalEvent = {...calEvent, rsvp: rsvp}
    setCalEvent(newCalEvent)
    setLoading(false)
    setErrMsg("")
  }

  function rsvpChangeError(error) {
    setLoading(false)
    setErrMsg(error.message)
  }

  const notOnRosterText = calEvent.rostered && !calEvent.on_the_roster ? "Not on this Roster Currently, but Please Provide Availability." : ""

  let roster = ""
  if (featureEnabled(PublishedRosters) && calEvent.rostered && calEvent.published) {
    const f = () => {
      return (
        <>
          <div className='userhome-roster-link-div'>
            <Link to={`event/${calEvent.cal_entry_id}/roster`}>
              Roster
            </Link>
          </div>
        </>
      )
    }
    roster = f()
  }

  return (
    <div className='datecardeventitem-div'>
      <h3 className='datecardeventitem-h3'>{TitleCase(getSeasonNameFromSeasonId(player, calEvent.season_id))}</h3>
      <h3 className='datecardeventitem-h3'>{TitleCase(calEvent.title)}</h3>
      <h4 className='datecardeventitem-notonroster'>{notOnRosterText}</h4>
      <div>
        {getTeamDiv(player, calEvent.team_id)}
        <div>Time:</div>
        <div className='div-right'>{extractTime(calEvent.start)}</div>
        <div>Location:</div>
        <div className='div-right'>{renderLocation(calEvent)}</div>
        <div>Available:</div>
        <div className='div-right'>
          {selectOrLoader(calEvent, onRSVPChanged)}
          <ErrorMessage text={errMsg} />
        </div>
        {roster}
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { dateStrToShortDate } from '../../utils/date'
import { TitleCase } from '../../utils/formatting'
import DynamicTable from './DynamicTable'
import RosterSummary from './RosterSummary'

function getHeader(start, eventName) {
  const date = dateStrToShortDate(start)
  return (
    <>{date + " " + eventName}<br/><span className="half-width">Available</span><span className='half-width '>On Roster</span></>
  )
}

function getEventCell(eventId, player, roster, availability, handleStatusChange) {
  const rsvp = availability.get(player.id)
  const available = rsvp === "none" ? "?" : rsvp === "yes" ? "Yes" : "No"

  if (roster === null) {
    return (
      <>
        <span className="half-width">{available}</span>
        <span className='half-width '>-</span>
      </>
    )
  }

  const onRoster = roster.isOnTheRoster(player.id) ? "yes" : "no"
  return (
    <>
      <span className="half-width">{available}</span>
      <span className='half-width '>
        <select className='slct' defaultValue={onRoster} onChange={event => {
          const val = event.target.value
          handleStatusChange(eventId, player.id, val === "yes")
        }}>
          <option id="yes" value="yes">Yes</option>
          <option id="no" value="no">No</option>
        </select>
      </span>
    </>
  )
}

export default function RosterEditor({rosterEditorData, closeRosterEditor, saveRosters, summariesOnly}) {
  const {id, players, eventAvailability, initialEventRosters, rosterEditorArgs} = rosterEditorData
  const [eventRosters, setEventRosters] = useState(initialEventRosters)

  function onRosterStatusChange(eventId, playerId, onRoster) {
    const roster = eventRosters[eventId]
    let updated = null
    
    if (onRoster) {
      updated = roster.addPlayerToRoster(playerId)
    } else {
      updated = roster.removePlayerFromRoster(playerId)
    }

    const newEventsRosters = {...eventRosters}
    newEventsRosters[eventId] = updated
    setEventRosters(newEventsRosters)
  }

  function saveRosterEditorData() {
    saveRosters(id, eventRosters, () => {
      
    }, errorMessage => {
      console.error(errorMessage)
    })
  }

  function renderSummaries() {
    return (
      <div className='roster-summary-container-div'>
      {rosterEditorArgs.events.map(event => {
        if (event.rostered) {
          const availability = eventAvailability[event.id]
          const roster = eventRosters[event.id]
          return (
            <div className='roster-summary-div'>
              <span><RosterSummary eventName={event.title} players={players} roster={roster} availability={availability}/></span>
            </div>
          )
        }

        return null
      })}
      </div>
    )
  }

  // build the table
  const headers = []
  const rows = []
  headers.push(...["Name", "Team", "Position"])
  for (const event of rosterEditorArgs.events) {
    headers.push(getHeader(event.start, event.title))
  }
  headers.push("")

  let numDifferences = 0
  for (const player of players) {
    const cols = []
    cols.push(TitleCase([player.first, player.last].join(" ")))
    cols.push(player.team_name)
    cols.push(TitleCase(player.position))

    let differs = false
    for (const event of rosterEditorArgs.events) {
      const hasRoster = initialEventRosters[event.id] ? true : false
      if (!hasRoster) {
        cols.push(getEventCell(event.id, player, null, eventAvailability[event.id], null))
      } else {
        if (initialEventRosters[event.id].isOnTheRoster(player.id) !== eventRosters[event.id].isOnTheRoster(player.id)) {
          differs = true
        }

        cols.push(getEventCell(event.id, player, eventRosters[event.id], eventAvailability[event.id], onRosterStatusChange))
      }
    }

    cols.push(differs ? '*' : '')
    numDifferences += differs ? 1 : 0

    rows.push({cols: cols})
  }

  if (summariesOnly) {
    return (
      <>
        <h3>{rosterEditorArgs.seasonName}</h3>
        {renderSummaries()}
      </>
    )
  }
  return (
    <>
      <h3>{rosterEditorArgs.seasonName}</h3>
      {renderSummaries()}
      <DynamicTable headers={headers} rows={rows}/>
      <br/>
      <div className='div-right full-width'>
        <button className='btn btn-cancel' onClick={() => closeRosterEditor()}>Close</button>
        <button className='btn' disabled={numDifferences === 0} onClick={saveRosterEditorData}>Save</button>
      </div>
    </>
  )
}

import React from 'react'
import { RiseLoader } from 'react-spinners'

export default function ButtonOrBusy({className, disabled, busy, text}) {
  if (busy) {
    return (
      <RiseLoader loading={true} color="#36a7d7"/>
    )
  } else {
    return (
      <button className={className} disabled={disabled}>{text}</button>
    )
  }
}

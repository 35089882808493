import React from 'react'
import DynamicTable from './DynamicTable'

export default function CsvDisplayTable({rows, headers, mapping, enableImport, onImport}) {
  if (rows === null || rows.length === 0 || headers === null || headers.length === 0 || mapping === null || mapping.size === 0) {
    return null
  }

  const reverseMap = new Map()
  for (const [key, value] of mapping.entries()) {
    reverseMap.set(value, key)
  }

  // loop through the headers and create a list of display headers that are keys in the mapping
  const displayHeaders = []
  const fieldIndex = new Map()
  for (let i = 0; i < headers.length; i++) {
    const header = headers[i]
    if (reverseMap.has(header)) {
      displayHeaders.push(reverseMap.get(header))
      fieldIndex.set(reverseMap.get(header), i)
    }
  }

  // loop through the rows and create a list of display rows
  const displayRows = []
  const submitRows = []
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const displayRowCols = []
    const submitRow = {}
    for (let j = 0; j < displayHeaders.length; j++) {
      const field = displayHeaders[j]
      const index = fieldIndex.get(field)
      displayRowCols.push(row[index])
      submitRow[field] = row[index]
    }
    displayRows.push({cols:displayRowCols})
    submitRows.push(submitRow)
  }

  function maybeGetImportButton() {
    if (!enableImport) {
      return null
    }
    
    return (
      <form className='admin-row full-width' onSubmit={event => {
          event.preventDefault()
          onImport(submitRows)
        }}>
        <button className='btn btn-primary full-width' type='submit'>Preview</button>
      </form>
    )
  }

  return (
    <section className='admin-content-section'>
      {maybeGetImportButton()}
      <DynamicTable headers={displayHeaders} rows={displayRows} maxRows={-1} />
    </section>
  )
}

import React, { useState } from 'react'
import ErrorMessage from './ErrorMessage';
import { useDebounceCB } from '../../hooks/useDebounce'

export default function LabeledInput(props) {
  let {initialValue} = props
  const {name, validate, handleChange, delay} = props
  const inputProps = { name: name, id: name, type: props.type, disabled: props.disabled}
  const label = props.label.length !== 0 ? (<label htmlFor={name}>{props.label}</label>) : (<></>)

  if (initialValue === undefined) {
    initialValue = ""
  }

  const [value, setValue] = useState(initialValue)
  const [errMsg, setErrMsg] = useState("")

  function debounceCB(val) {
    if (validate) {
      const [isValid, err] = validate(val)
      handleChange(isValid, val)
      setErrMsg(err)
    } else {
      handleChange(true, val)
    }
  }

  useDebounceCB(value, delay, val => debounceCB(val))

  if (props.maxLength) {
    inputProps.maxLength = props.maxLength
  }
  
  //console.log("value for", props.label, "is", value)
  return (
    <div>
        {label}
        {(() => {
            return (<input className="inpt" {...inputProps} value={value} onChange={event => {
              //console.log("value changed for", props.label, "to", event.target.value)
              setValue(event.target.value)
            }}/>)
        })()}
        <ErrorMessage text={errMsg} />
    </div>
  )
}

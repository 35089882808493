import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { AmplifyAPI } from '../../utils/api'
import { ParsePlayer } from '../../utils/wire'
import GuardianInfo from '../components/GuardianInfo'
import { TitleCase } from '../../utils/formatting'
import { RiseLoader } from 'react-spinners'

const adminApi = new AmplifyAPI("AdminApi")

export default function Player() {
  const {id} = useParams()
  const [playerData, setPlayerData] = useState(null)

  useEffect(() => {
    const path = "/players"
    const params = {"player_id":id}
    adminApi.get(path, params, results => {
      console.log("/players?player_id={id} results", results)
      setPlayerData(ParsePlayer(results))
    }, error => console.error(error))
  },[id])

  const [guardians, setGuardians] = useState()
  useEffect(() => {
    const path = "/players/guardians"
    const params = {"player_id":id}
    adminApi.get(path, params, results => {
      console.log("/players/guardians?player_id={id} results", results)
      if (!results) {
        results = []
      }

      setGuardians(results)
    }, error => console.error(error))
  },[id])
  

  if (playerData == null || guardians == null) {
    return (
      <section className='admin-content-section'>
        <div className='div-loader'>
          <RiseLoader loading={true} size="25px" color="#36a7d7"/>
        </div>
      </section>
    )
  } else {
    return (
      <section className='admin-content-section'>
        <div className='admin-container-narrow'>
          <h3 className='div-center'>
            Player Info
            <Link to={"/admin/player/" + playerData.id + "/edit"} >
              <img src="/edit_icon.png" alt="info" width="24px" height="24px" />
            </Link>
          </h3>
          <div className='admin-row'>Name:</div><div className='admin-row div-right'>
            {TitleCase([playerData.first, playerData.middle, playerData.last].join(' '))}
          </div>
          <div className='admin-row'>Position:</div><div className='admin-row div-right'>{TitleCase(playerData.position)}</div>
          <div className='admin-row'>Grad Year:</div><div className='admin-row div-right'>{playerData.grad_year}</div>
          <div className='admin-row'>USA Lax Membership:</div><div className='admin-row div-right'>{playerData.usalax_mem_number}</div>
          <div className='admin-row'>USA Lax Expiration:</div><div className='admin-row div-right'>{playerData.usalax_mem_expiration}</div>
          <div className='admin-row'>USA Lax Zip:</div><div className='admin-row div-right'>{playerData.usalax_zip}</div>
        </div>
          {guardians.map(info => {
            return (<>
              <div className='admin-container-narrow'>
                <GuardianInfo info={info} />
              </div></>)
          })}
      </section>
    )
  }
}
